/**
 * This class contains all methods which are needed to finally send the city signup form
 */

class CitySignupSubmitForm {
    constructor(citySignupForm, citySignupValidation, translationData, data) {
        this.citySignupForm = citySignupForm;
        this.citySignupValidation = citySignupValidation;
        this.translationData = translationData;
        this.data = data;
    }

    /** geprüft */
    citySignupSaveTemporary()
    {
        let citySaveFeedback = $("#sr_commune_save_feedback");
        let cityRegFeedback = $("#sr_commune_reg_feedback");
        let mainMail = $("input[name=sr_creg_contact_email]");

        this.citySignupForm.trim_all();

        cityRegFeedback.html("");
        citySaveFeedback.html("");

        if (!this.citySignupValidation.validate_email_or_empty(mainMail) ||
            !this.citySignupValidation.validate_nonempty(mainMail))
        {
            this.citySignupValidation.showErrorMessage(6000, this.translationData.missingEmail);
            return false;
        }

        if (citySignUpConfig.g_formAJAXSaveRequest) {
            this.citySignupValidation.showErrorMessage(2000, this.translationData.dataAlreadySend);
            //g_formAJAXSaveRequest.abort();
            return;
        }

        const form = this;
        citySignUpConfig.g_formAJAXSaveRequest = $.ajax({
            url:  this.data.formUrl + "&sr_action=save_temp_cityreg",
            type: "POST",
            data: JSON.stringify( this.citySignupForm.serialize_all() ),
            complete: function () {
                citySignUpConfig.g_formAJAXSaveRequest = false;
            },
            success: function (data) {
                citySaveFeedback.html("<br><b><span class='sr_form_feedback_blue'>" + data + "</span><br>");
            },
            error: function (xhr, ajaxOptions, thrownError) {
                if (!isNaN(xhr.responseText)) {
                    form.citySignupValidation(2000, xhr.responseText);
                } else {
                    form.citySignupValidation(2000, form.translationData.savingError);
                }
            }
        });

        return true;
    }

    /** geprüft */
    citySignupEdit() {
        let editButton = $('#edit_button');

        if (citySignUpConfig.g_update_basket_disabled === true) {
            citySignUpConfig.g_update_basket_disabled = false;
            citySignUpConfig.g_forceRecalculate = 1;
            this.citySignupValidation.showErrorMessage(2000, this.translationData.editEnabled);
            editButton.html(this.translationData.editDisabled);
        } else {
            citySignUpConfig.g_update_basket_disabled = true;
            this.citySignupValidation.showErrorMessage(2000, this.translationData.editDisabled);
            editButton.html(this.translationData.editEnabled);
        }
    }

    /** geprüft */
    async citySignupSubmitPreparation(action)
    {
        const form = this;
        const citySignupInitialization = new CitySignupInitialization(this.translationData);
        let mainCityUUID = $('#sr_creg_uuid').val();
        let saveFeedback = $("#sr_commune_save_feedback");
        let fund_available = citySignUpConfig.g_creg_available_promotion > 0;
        let attendencePrice = 0;
        let startDate = $("#sr_creg_start_date");
        let priceList = $('#attendence-overview .price-list');
        let finishModal = $('#sr-creg-kommunenregistrierung-finish');
        let finishText = $('#sr-creg-kommunenregistrierung-finish .modal-body p');
        let finishButton = $('#sr-creg-final-finish');
        let countryId = $('#sr_creg_country');
        let fstate = $('#sr_creg_fstate');

        if (!mainCityUUID) {
            const ags = $('#sr_creg_ags').val();
            await $.ajax({
                url: this.data.apiUrl + "city/ags_info?sr_ags=" + encodeURIComponent(ags) + "&sr_api_key=" + this.data.apiKey,
                crossDomain: true,
                xhrFields: {
                    withCredentials: true
                },
                dataType: "json",
                success: function (data) {
                    mainCityUUID = data.uuid
                }
            });
        }

        const blacklistedStatus = await citySignupInitialization.getBlacklistedStatus(this.data.apiUrl, this.data.apiKey, mainCityUUID);

        if (blacklistedStatus) {
            let text = this.translationData.blacklistedCity;
            const utility = new Utility();
            utility.showPermanentErrorModal($('#sr-permanent-error-modal'), $('#sr-permanent-error-modal .sr-error-message-text'), text);
            return;
        }


        this.citySignupForm.citySignupShoppingBasketInit.citySignupShoppingBasketUpdate.citySignupShoppingBasketCalculation.createBasketJSON();
        this.citySignupForm.trim_all();
        mandatoryFieldArray[4] = this.citySignupValidation.getMissingMandatoryFieldItems();

        // set the ! on the StepWizard
        const step = document.querySelector('.sr-creg-step-city[data-step="4"]');
        if (mandatoryFieldArray[4].length > 0) {
            step.querySelector('.missing-field-info').classList.remove('hidden');
        } else {
            step.querySelector('.missing-field-info').classList.add('hidden');
        }

        saveFeedback.html("");

        let radarValid = this.citySignupValidation.validate_radar_date_range();

        let citySignupAddSubcity = new CitySignupAddSubCity(this.data, this.translationData);
        let currentOffset = citySignupAddSubcity.getCurrentOffset();
        citySignupAddSubcity.checkSingleSubCitiesOnMandatoryFields(this.citySignupForm, false, false);

        let subCities = document.querySelectorAll('.akkordeon-topics');

        subCities.forEach((item) => {
            const subCityOffset = item.getAttribute('data-sr-offset');
            if (!this.citySignupValidation.validate_radar_date_range(parseInt(subCityOffset))) {
                radarValid = false;
            }
        })

        // check if fstate is not "-"
        if (countryId.val() === "57" && fstate.val() === "-") {
            this.citySignupValidation.showErrorMessage(2000, this.translationData.wrongFstate);
            return false;
        }

        if (!radarValid) {
            this.citySignupValidation.showErrorMessage(2000, this.translationData.radarDateFailure);
            return false;
        }

        for (let site in mandatoryFieldArray) {
            if (mandatoryFieldArray[site].length > 0) {
                for (let i = 1; i <= 4; i++) {
                    if (i === parseFloat(site)) {
                        $('.step-city[data-step=' + i + ']').removeClass('hidden');
                        this.citySignupValidation.implementEventListenerForCheckingMandatoryFields();
                    } else {
                        $('.step-city[data-step=' + i + ']').addClass('hidden');
                    }
                }

                // show correct submitbuttons
                if (parseFloat(site) !== 4) {
                    $('#toNextStep').removeClass('hidden');
                    $('#finalSubmitButton').addClass('hidden');
                }

                if (parseFloat(site) === 1) {
                    $('#toBeforeStep').addClass('hidden');
                } else {
                    $('#toBeforeStep').removeClass('hidden');
                }

                this.citySignupValidation.showErrorMessage(2000, this.translationData.missingField);

                setTimeout(() => {
                    if (mandatoryFieldArray[2].length > 0) {
                        for (let subCity in mandatoryFieldArray[2][0]) {
                            $(mandatoryFieldArray[2][0][subCity].item).parents('.akkordeon-topic-body').collapse('show');
                            $(mandatoryFieldArray[2][0][subCity].item)[0].scrollIntoView({behavior: "smooth", block: "center", inline: "center"});
                        }
                    } else {
                        $(mandatoryFieldArray[site][0].item)[0].scrollIntoView({behavior: "smooth", block: "center", inline: "center"});
                    }
                }, 1000);

                return false;
            }
        }

        if (this.checkIfLastSubCityGotFilledValues()) {
            this.citySignupValidation.showErrorMessage(4000, this.translationData.subCityNotSaved);
            return;
        }

        if (this.data.adminMode === 0 && !startDate.val().trim()) {
            if (!confirm(this.translationData.missingMainDate)) {
                return false;
            }
        }

        if (citySignUpConfig.g_formAJAXSaveRequest) {
            this.citySignupValidation.showErrorMessage(2000, this.translationData.dataAlreadySend);
            //g_formAJAXSaveRequest.abort();
            return;
        }

        if (citySignUpConfig.g_update_basket_disabled === false) {
            const country = citySignUpConfig.g_country;

            // bei einer ausländischen Kommune gibt es keinen Fördertopf, da hier 20% grundsätzlich gefördert werden, wird fund_available grundsätzlich auf true gesetzt
            if (country === 57 || country === "57") {
                fund_available = this.check_promotion_fund();
            } else {
                fund_available = true;
            }

            priceList.each(function (index, item) {
                attendencePrice = attendencePrice + parseFloat($(item).attr('data-sr-json'));
            });

            // wird angezeigt, wenn die Teilnahmegebühr größer als die verfügbare Förderung ist
            // UND die verfügbare Förderung nicht Null ist
            // UND die action auf register liegt (also nur bei der Anmeldung, nicht bei der Freischaltung
            // UND wenn der Fördertopf kein Pauschalfördertopf ist
            // UND wenn der Fördertopf aktiv ist

            // Oder wenn der fund_available (prüft ob genügend Förderung im Fördertopf vorhanden ist und ob der Fördertopf aktiv ist) auf false steht

            if ((attendencePrice > citySignUpConfig.g_creg_available_promotion
                && citySignUpConfig.g_creg_available_promotion != null
                && action === 'register'
                && citySignUpConfig.g_creg_total_promotion !== -1
                && citySignUpConfig.g_creg_promotion_active === 1)
                || fund_available === false)
            {
                finishModal.modal('show');
                finishText.html(this.translationData.promotionMessage)
                finishButton.off('click').on('click', function(e) {
                    return form.citySignupSubmit(action);
                });
            } else {
                return form.citySignupSubmit(action);
            }
        } else {
            return form.citySignupSubmit(action);
        }
    }

    checkIfLastSubCityGotFilledValues() {
        const agsValue = $('#subcity-new-content-wrapper [name="sr_creg_ags"]').val();

        if (agsValue && agsValue !== "-") {
            return true;
        }
        return false;
    }

    /** geprüft */
    check_promotion_fund()  {
        const form = this;
        let fund_available = true;
        let infoPrice = $('#attendence-info-price');
        let data = {
            promotion: infoPrice.attr('data-sr-promotion'),
            fstate: $('#sr_creg_fstate').val()
        }

        $.ajax({
            url: this.data.formUrl + "check_promotion&sr_returnmode=" + this.data.returnMode,
            type: "POST",
            async: false,
            data: JSON.stringify(data),
            success: function (promotion) {
                promotion = JSON.parse(promotion);
                let currentPromotion = parseFloat(infoPrice.attr('data-sr-promotion'));
                let tempPromotion = parseFloat(promotion.promotion_available);
                citySignUpConfig.g_creg_total_promotion = parseFloat(promotion.promotion_total);
                citySignUpConfig.g_creg_promotion_active = promotion.active;

                // wenn die errechnete Förderung höher ist als die vorhandene Förderung
                // UND wenn der Fördertopf kein pauschaler Fördertopf ist
                // UND wenn der Fördertopf aktiv ist

                // Dann setze fund_available auf false und zeige Hinweismeldungen im Warenkorb an
                if (currentPromotion >= tempPromotion && promotion.promotion_total > -1 && promotion.active === 1) {
                    citySignUpConfig.g_creg_available_promotion = parseFloat(promotion.promotion_available);
                    infoPrice.attr('data-sr-promotion', citySignUpConfig.g_creg_available_promotion);
                    infoPrice.html(srCurrencyNumberFormat(citySignUpConfig.g_creg_available_promotion / 100, '€'));

                    form.citySignupForm.citySignupShoppingBasketInit.citySignupShoppingBasketUpdate.citySignupShoppingBasketCalculation.calculateTotal();
                    form.citySignupForm.citySignupShoppingBasketInit.citySignupShoppingBasketUpdate.citySignupShoppingBasketCalculation.createBasketJSON();
                    fund_available = false;
                }

            }
        });

        return fund_available;
    }

    /** geprüft */
    citySignupSubmit(action)
    {
        const form = this;
        if (action !== "save" && this.data.confirmText && !confirm(this.data.confirmText)) {    // Only show if a confirm text is set
            return false;
        }

        $(".button-submit").prop("disabled", "disabled");
        $('#waitModal').modal('show');

        $('#sr_prevyear_feedback_wrapper').addClass('hidden');

        citySignUpConfig.g_formAJAXSaveRequest = $.ajax({
            url:  this.data.formUrl + action + "&sr_returnmode=" + this.data.returnMode + "&force_recalculate=" + citySignUpConfig.g_forceRecalculate + "&XDEBUG_SESSION=IDEA",
            type: "POST",
            data: JSON.stringify( this.citySignupForm.serialize_all() ),
            timeout: 0,
            complete: function () {
                citySignUpConfig.g_formAJAXSaveRequest = false;
                $(".button-submit").removeAttr("disabled");
                $('#waitModal').modal('hide');
            },
            success: function (response) {
                $('#waitModal').modal('hide');
                const data = JSON.parse(response);

                if (data.success) {
                    // show final site
                    $('#city-registration-header h1').html('Die Anmeldung Ihrer Kommune wurde erfolgreich abgeschlossen.');
                    $('#city-registration-header .info').addClass('hidden');
                    $('.wizard').addClass('hidden');
                    $('#step-city-overview').addClass('hidden');
                    $('.final-buttons').addClass('hidden');
                    $('#step-city-final-site').removeClass('hidden');
                } else if (data.redirect === true) {
                    window.location.href = data.link;
                } else {
                    //$("#sr_commune_reg_feedback").html(data);
                    form.citySignupValidation.showPermanentErrorMessage(data.msg);
                }

            },
            error: function (xhr, ajaxOptions, thrownError) {
                $('#waitModal').modal('hide');
                if (!isNaN(xhr.responseText) && xhr.responseText) {
                    form.citySignupValidation.showErrorMessage(2000, xhr.responseText);
                } else {
                    form.citySignupValidation.showErrorMessage(2000, form.translationData.savingError);
                }
            }
        });
    }
}

window.CitySignupSubmitForm = CitySignupSubmitForm;
