/**
 * This class contains the methods which are responsible to initialize the interactions with the city signup form
 */

class CitySignupInitialization {

    constructor(translationData) {
        this.translationData = translationData;
    }

    /** geprüft */
    initializePopover() {
        $("#sr_creg_cityname").on({
            'focus': function() {
                $("#sr_creg_cityname_hover").popover('show');
            },
            'focusout': function() {
                $("#sr_creg_cityname_hover").popover('hide');
            }
        });

        $("#sr_creg_ags").on({
            'focus': function() {
                $("#sr_creg_ags_hover").popover('show');
            },
            'focusout': function() {
                $("#sr_creg_ags_hover").popover('hide');
            }
        });

        $("#sr_creg_population").on({
            'focus': function() {
                $("#sr_creg_population_hover").popover('show');
            },
            'focusout': function() {
                $("#sr_creg_population_hover").popover('hide');
            }
        });

        $("#sr_creg_pl_num").on({
            'focus': function() {
                $("#sr_creg_pl_num_hover").popover('show');
            },
            'focusout': function() {
                $("#sr_creg_pl_num_hover").popover('hide');
            }
        });

        $("#sr_creg_start_date").on({
            'focus': function() {
                $("#sr_creg_start_date_hover").popover('show');
            },
            'focusout': function() {
                $("#sr_creg_start_date_hover").popover('hide');
            }
        });

        $("#sr_creg_contact_institution").on({
            'focus': function() {
                $("#sr_creg_contact_institution_hover").popover('show');
            },
            'focusout': function() {
                $("#sr_creg_contact_institution_hover").popover('hide');
            }
        });

        $("#sr_creg_invoice_institution").on({
            'focus': function() {
                $("#sr_creg_invoice_institution_hover").popover('show');
            },
            'focusout': function() {
                $("#sr_creg_invoice_institution_hover").popover('hide');
            }
        });

        $("#sr_creg_primary_email").on({
            'focus': function() {
                $("#sr_creg_primary_email_hover").popover('show');
            },
            'focusout': function() {
                $("#sr_creg_primary_email_hover").popover('hide');
            }
        });

        $("#sr_creg_coordination_institution").on({
            'focus': function() {
                $("#sr_creg_coordination_institution_hover").popover('show');
            },
            'focusout': function() {
                $("#sr_creg_coordination_institution_hover").popover('hide');
            }
        });
    }

    /** geprüft */
    initializeGeneralEventListener(citySignupForm, data) {
        let coordinationSelection = $("#sr_creg_coordination_section");
        let coordinationEnabled = $("#sr_creg_coordination_enabled");
        let invoiceSection = $("#sr_creg_invoice_section");
        let invoiceEnabled = $("#sr_creg_invoice_enabled");
        let eDocumentSection = $("#sr_creg_edocument_section");
        let eDocumentEnabled = $("#sr_creg_edocument_enabled");
        let addExtraCoordination = $('.add_extra_coordination');
        let lookupCoordinates = $('.lookupCoordinates').first();
        let fstate = $("#sr_creg_fstate");
        let contractOpen = $(".sr_contract_open");
        let population = $("#sr_creg_population");

        addExtraCoordination.off('click').on('click', () => {
           citySignupForm.add_extra_coordination();
        });

        $("#sr_creg_citytype_preset").change(function() {
            const selectedOption        = $("#sr_creg_citytype_preset option:selected");
            const selectedOptionArticle = selectedOption.attr("data-sr-article-de");
            if (selectedOption && selectedOptionArticle && selectedOption.val() !== "EigeneBezeichnung") {
                $("#sr_creg_citytype").val( selectedOption.html() );
                $("#sr_creg_citytype_article").val( selectedOptionArticle );
            }
        });

        $('#sr_creg_citytype_preset').on({
            'change': function() {
                if (($(this).val() === "EigeneBezeichnung")) {
                    // .css display is an alternative in a table for show()
                    $('#article').css('display', 'table-row');
                    $('#own_description').css('display', 'table-row');
                } else if (($(this).val() !== "EigeneBezeichnung")) {
                    $('#article').hide();
                    $('#own_description').hide();
                }

                if (data['cityTypesSubCity'].indexOf($(this).val()) !== -1) {
                    $('#sr-creg-step-subcities').parent('li').removeClass('hidden');

                    $('.sr-creg-step-city').parent('li:not(.hidden)').each((index, item) => {
                        $(item).children('a').children('.round-tab').html(index + 1);
                    });
                } else {
                    $('#sr-creg-step-subcities').parent('li').addClass('hidden');

                    $('.sr-creg-step-city').parent('li:not(.hidden)').each((index, item) => {
                        $(item).children('a').children('.round-tab').html(index + 1);
                    });
                }

                if($('.sr-creg-step-city').parent('li:not(.hidden)').length === 2){
                    $('.connecting-line').css('width', '150px');
                } else if ($('.sr-creg-step-city').parent('li:not(.hidden)').length === 3) {
                    $('.connecting-line').css('width', '375px');
                } else if ($('.sr-creg-step-city').parent('li:not(.hidden)').length === 4) {
                    $('.connecting-line').css('width', '550px');
                }
            }
        });

        coordinationSelection.hide();
        coordinationEnabled.change(function() {
            if (coordinationEnabled.is(":checked")) {
                coordinationSelection.show();
            } else {
                coordinationSelection.hide();
                coordinationSelection.find("select,input:not([type=checkbox]):not([type=radio])").val("").change();
                coordinationSelection.find("input[type=checkbox]").removeAttr("checked").change();
                coordinationSelection.find(".sr_form_feedback").html("");
            }
        });

        invoiceSection.hide();
        invoiceEnabled.change(function() {
            if (invoiceEnabled.is(":checked")) {
                invoiceSection.show();
            } else {
                invoiceSection.hide();
                invoiceSection.find("select,input:not([type=checkbox]):not([type=radio])").val("").change();
                invoiceSection.find("input[type=checkbox]").removeAttr("checked").change();
                invoiceSection.find(".sr_form_feedback").html("");
            }
        });

        eDocumentSection.hide();
        eDocumentEnabled.change(function() {
            if (eDocumentEnabled.is(":checked")) {
                eDocumentSection.show();
            } else {
                eDocumentSection.hide();
                eDocumentSection.find("select,input:not([type=checkbox]):not([type=radio])").val("").change();
                eDocumentSection.find("input[type=checkbox]").removeAttr("checked").change();
                eDocumentSection.find(".sr_form_feedback").html("");
            }
        });

        lookupCoordinates.off('click').on('click', (e) => {
            citySignupForm.lookup_coordinates();
        });

        fstate.on('change', (event) => {
            const selectedState = $(event.currentTarget).val();

            if (selectedState !== "-" && selectedState !== "" && ($('#sr-creg-promotion-modal-body').find("[data-fstate=" + $("#sr_creg_fstate").val() + "]").length !== 0) ) {
                $("#sr_creg_promo").addClass("sr_validate_checked_always");
                // hide promotion condition text of all states in modal
                $(".promotion-state-text").addClass("hidden");
                // show checkbox only after selecting state with conditions
                $("#accept-promotion-conditions").removeClass("hidden");
                // show selected state conidition text in modal
                $("[data-fstate=" + selectedState + "]").removeClass("hidden");
            } // otherwise hide the accepted promotion checkbox & remove the validation check
            else {
                $("#sr_creg_promo").removeClass("sr_validate_checked_always");
                $("#accept-promotion-conditions").addClass("hidden");
            }

            const bayernCheckboxWrapper = $("#accept-promotion-bayern");
            const bayernCheckbox = $("#sr_creg_promo_bayern");
            if (selectedState === "Bayern" && data.activateBayernCheckbox === 1) {
                bayernCheckboxWrapper.removeClass("hidden");
                bayernCheckbox.addClass("sr_validate_checked_always");

                $.ajax({
                    url: data.formUrl + "updateShoppingBasket&sr_returnmode=" + data.returnMode + "&XDEBUG_SESSION=IDEA",
                    type: "POST",
                    data: JSON.stringify(citySignupForm.serialize_all()),
                    complete: function () {
                        citySignUpConfig.g_formAJAXSaveRequest = false;
                    },
                    success: function (response) {
                        let result;
                        result = JSON.parse(response);

                        if (result.availablePromotion === 0) {
                            bayernCheckboxWrapper.addClass("hidden");
                            bayernCheckbox.removeClass("sr_validate_checked_always");
                        }
                    }
                });
            }

            // otherwise hide the accepted promotion checkbox & remove the validation check
            else {
                bayernCheckboxWrapper.addClass("hidden");
                bayernCheckbox.removeClass("sr_validate_checked_always");
            }

            const brandenburgCheckboxWrapper = $("#accept-promotion-brandenburg");
            const brandenburgCheckbox = $("#sr_creg_promo_brandenburg");
            const brandenburgCheckboxTableForMandatoryFlag = $("#accept-promotion-brandenburg td.check");
            if (selectedState === "Brandenburg" && data.activateBrandenburgCheckbox === 1) {
                brandenburgCheckboxWrapper.removeClass("hidden");
                brandenburgCheckboxTableForMandatoryFlag.addClass("sr_validate_checked");

                $.ajax({
                    url: data.formUrl + "updateShoppingBasket&sr_returnmode=" + data.returnMode + "&XDEBUG_SESSION=IDEA",
                    type: "POST",
                    data: JSON.stringify(citySignupForm.serialize_all()),
                    complete: function () {
                        citySignUpConfig.g_formAJAXSaveRequest = false;
                    },
                    success: function (response) {
                        let result;
                        result = JSON.parse(response);

                        if (result.availablePromotion === 0) {
                            brandenburgCheckboxWrapper.addClass("hidden");
                            brandenburgCheckboxTableForMandatoryFlag.removeClass("sr_validate_checked");
                        }
                    }
                });
            }

            // otherwise hide the accepted promotion checkbox & remove the validation check
            else {
                brandenburgCheckboxWrapper.addClass("hidden");
                brandenburgCheckbox.removeClass("sr_validate_checked_always");
            }

            // show Meldeplattform Radar in R!-Section for Cities in Hessen
            const ivmSwitcher =  $("[data-sr-name=sr_ivm_switcher]");

            if (selectedState === 'Hessen') {
                ivmSwitcher.removeClass('hide')
            } else {
                ivmSwitcher.addClass('hide')
            }
        });

        contractOpen.click(function() {
            let cityType = $("#sr_creg_citytype").val().trim();
            let cityName = $("#sr_creg_cityname").val().trim();

            if (!cityType || !cityName) {
                citySignupForm.citySignupValidation.showErrorMessage(2000, citySignupForm.translationData.ownDiscriptionErrorMsg);
                $("html, body").animate({ scrollTop: 200 }, 1000);
                return false;
            }
            let baseUrl      = $(this).attr("data-base-url"); //="{BASE_URL}" data-language-code="{LOCALE:LANGUAGE_ID}"
            let languageCode = $(this).attr("data-language-code");
            window.open(baseUrl + "/specials/contract/create?sr_citytype=" + encodeURIComponent(cityType) + "&sr_cityname=" + encodeURIComponent(cityName) + "&L=" + languageCode, "_blank");
            return false;
        });

        population.on("change keyup blur", function() {
            let population = $(this).val();
            population = population.replace(",", "");
            population = population.replace(".", "");
            $(this).val(population);
        });
    }

    /** geprüft */
    initializeEventListenerRadarSection(citySignupForm, citySignupValidation) {
        let radarSection = $("#sr_creg_radar_section");
        let radarEnabled = $("#sr_creg_radar_enabled");
        let radarContact = $("#sr_creg_radar_contact");
        let radarContactSection = $("#sr_creg_radar_contact_section");
        let radarPreYearCheckbox = $('#sr_creg_admin_radar_preyear');
        let radarEnabledSection = $('#sr_creg_admin_radar_wrapper');

        radarPreYearCheckbox.change(function() {
           if ($(this).is(":checked")) {
               radarSection.hide();
               radarEnabledSection.hide();
           } else {
               radarEnabledSection.show();
           }
        });

        radarSection.hide();
        radarEnabled.change(function() {
            if ($(this).is(":checked")) {
                radarSection.show();
                if (radarContact.is(":checked")) {
                    radarContactSection.show();
                    $("#sr_creg_radar_is_radar_admin").val("on");
                }
                $(".sr_kommunenregistrierung_sub").each(function() {
                    let offset = $(this).attr("data-sr-offset");
                    let radarEnabledOffset = $("#sr_creg_radar_enabled_"+offset);
                    radarEnabledOffset.removeAttr("checked").change();
                    radarEnabledOffset.attr("readonly", "readonly");
                    $("#sr_creg_radar_enabled_label_"+offset).css("opacity", 0.5);
                });
            } else {
                radarSection.hide();
                radarSection.find("select,input:not([type=checkbox]):not([type=radio])").val("");
                radarSection.find("input[type=checkbox]").removeAttr("checked").change();
                $("#sr_creg_radar_previous_active").removeAttr("checked").change();
                $("#sr_creg_radar_contact").removeAttr("checked").change();
                $("#sr_creg_radar_duration_1").click().change();
                $("#sr_creg_radar_end_date").datepicker("update");
                $("#sr_creg_radar_start_date").datepicker("update");
                $(".sr_kommunenregistrierung_sub").each(function() {
                    let offset = $(this).attr("data-sr-offset");
                    $("#sr_creg_radar_enabled_label_"+offset).css("opacity", 1.0);
                    $("#sr_creg_radar_enabled_"+offset).removeAttr("readonly");
                });
            }
        });
        radarContactSection.hide();
        radarContact.change(function() {
            if (radarContact.is(":checked") && $("#sr_creg_radar_enabled").is(":checked")) {
                $("#sr_creg_radar_contact_section").show();
                $("#sr_creg_radar_is_radar_admin").val("on");
            } else {
                radarContactSection.find("select,input:not([type=checkbox]):not([type=radio])").val("").change();
                radarContactSection.find("input[type=checkbox]").removeAttr("checked").change();
                radarContactSection.find(".sr_form_feedback").html("");
                radarContactSection.hide();
            }
        });

        $("#sr_creg_radar_date_from_campaign,#sr_creg_radar_date_from_campaign_tdd").click(function() {
            citySignupForm.update_enddate();
            let startDate = $("#sr_creg_start_date");
            if (startDate.val()) {
                $("#sr_creg_radar_start_date").datepicker("setDate", startDate.datepicker("getDate")).change();
            } else {
                $("#sr_creg_radar_start_date").val("").datepicker("update");
            }
            if (citySignUpConfig.g_endDate) {
                $("#sr_creg_radar_end_date").datepicker("setDate", citySignUpConfig.g_endDate).change();
            } else {
                $("#sr_creg_radar_end_date").val("").datepicker("update");
            }
            citySignupValidation.validate_radar_date_range();
        });
    }

    /**
     * abklären mit KB, welche Felder bei LU oder sonstigem Ausland angezeigt werden sollen
     */
    initializeEventListenerCountry(citySignupForm, countryArray, data) {
        let countrySelectField = $("#sr_creg_country");

        for (const key in countryArray) {
            const option = new Option(countryArray[key]['country_name:localized'], countryArray[key]['country_id']);
            countrySelectField.append(option);
        }

        // preselect germany on load of formular
        countrySelectField.val(57);

        countrySelectField.change(function() {
            citySignUpConfig.g_country = countrySelectField.val();
            let fstate = $("#sr_creg_fstate");
            let outsideFrance = $(".sr_hide_outside_france");
            let inFrance = $(".sr_hide_in_france");
            outsideFrance.addClass("hide");
            inFrance.removeClass("hide");

            if (countrySelectField.val() === "57") {    // Germany
                $(".sr_hidden_outside_germany").removeClass("sr-invisible");
                $(".sr_hide_outside_germany").removeClass("hide");
                $(".sr_visible_outside_germany").css("visibility", "hidden");
                $(".sr_show_outside_germany").addClass("hide");

                $("#sr_creg_vat_id").val("");

                $("input[data-sr-role=sr-ags-field]").each(function(idx, elem) {
                    if ($(elem).val().trim() === "-") {
                        $(elem).val("").change();
                    }
                });
            } else if ($("#sr_creg_country").val() === 75) {        // France
                $(".sr_hidden_in_france").addClass("sr-invisible");
                outsideFrance.removeClass("hide");
                inFrance.addClass("hide");
            } else {

                // No FState outside Germany
                fstate.val("-").change();

                // No AGFK outside Germany
                $("[name=sr_creg_is_agfk_member]").removeAttr("checked").change();

                // Default AGS "-" outside Germany
                $("input[data-sr-role=sr-ags-field]").each(function(idx, elem) {
                    if ($(elem).val().trim() === "") {
                        $(elem).val("-").change();
                    }
                });

                // No Merch outside Germany
                $("#sr_creg_merchandise_1").val(0);
                $("#sr_creg_merchandise_3").val(0);
                $("#sr_creg_merchandise_4").val(0);
                $("#sr_creg_merchandise_6").val(0);
                $("#sr_creg_merchandise_7").val(0);
                $("#sr_creg_merchandise_8").val(0);

                $(".sr_hidden_outside_germany").addClass("sr-invisible");
                $(".sr_hide_outside_germany").addClass("hide");
                $(".sr_visible_outside_germany").css("visibility", "inherit");
                $(".sr_show_outside_germany").removeClass("hide");
            }

            if (countrySelectField.val() === "134") {   // Luxembourg
                let startDate = $("#sr_creg_start_date");
                // No invoice address in LU
                $("[name=sr_creg_invoice_enabled]").removeAttr("checked").change();
                /*
                // No RADar! in LU
                $("[name=sr_creg_radar_enabled]").removeAttr("checked").change();
                $("[name=sr_creg_contact_is_radar_admin]").removeAttr("checked").change();
                $("[name=sr_creg_coordination_is_radar_admin]").removeAttr("checked").change();
                */
                // Special RADar! in LU (#1596)
                $("[data-sr-switch=tdd]").removeClass("hide");
                $("[data-sr-switch=no-tdd]").addClass("hide");
                $("input[data-sr-role=sr-ags-field]").each(function(idx, elem) {
                    if ($(elem).val().trim() === "-") {
                        $(elem).val("").change();
                    }
                });

                // No custom email in LU
                $("[name=sr_creg_wants_sr_email]").removeAttr("checked").change();
                // Default city type in LU
                if (!$("#sr_creg_citytype").val()) {
                    $("#sr_creg_citytype_preset").val("Gemeinde").change();
                }
                // Fixed dates in LU

                startDate.datepicker("setDate", new Date("{TDD_START_DATE}T00:00:00")).datepicker("update");
                citySignupForm.update_enddate();
                startDate.attr("readonly", "readonly");

                $(".sr_hidden_in_luxembourg").addClass("sr-invisible")
                $(".sr_show_in_luxembourg").removeClass("hide");
            } else if (countrySelectField.val() === "75") {        // France
                $(".sr_hidden_in_france").addClass("sr-invisible");
            } else {
                $("#sr_creg_start_date").removeAttr("readonly");

                // Special RADar! in LU (#1596)
                $("[data-sr-switch=tdd]").addClass("hide");
                $("[data-sr-switch=no-tdd]").removeClass("hide");

                $(".sr_hidden_in_luxembourg").removeClass("sr-invisible");
                $(".sr_hidden_in_france").removeClass("sr-invisible");
                $(".sr_show_in_luxembourg").addClass("hide");
            }

            // Require AGS in Germany or Luxembourg
            if (countrySelectField.val() === "57" || countrySelectField.val() === "134") {
                $("input[data-sr-role=sr-ags-field]").addClass("sr_validate_nonempty");
            } else {
                $("input[data-sr-role=sr-ags-field]").removeClass("sr_validate_nonempty");
            }

            // Force datepicker to re-evaluate beforeShowDay
            if (citySignUpConfig.g_regIsInitialized) {
                $("#sr_creg_start_date").datepicker("update");
            }

            // Update available team tags
            citySignupForm.update_team_tags(fstate.val());

            // remove merchandise step when not germany
            if (countrySelectField.val() !== "57") {
                $('#sr-creg-step-merchandise').parent('li').addClass('hidden');

                $('.sr-creg-step-city').parent('li:not(.hidden)').each((index, item) => {
                    $(item).children('a').children('.round-tab').html(index + 1);
                });
            } else {
                $('#sr-creg-step-merchandise').parent('li').removeClass('hidden');

                $('.sr-creg-step-city').parent('li:not(.hidden)').each((index, item) => {
                    $(item).children('a').children('.round-tab').html(index + 1);
                });
            }

            if($('.sr-creg-step-city').parent('li:not(.hidden)').length === 2){
                $('.connecting-line').css('width', '150px');
            } else if ($('.sr-creg-step-city').parent('li:not(.hidden)').length === 3) {
                $('.connecting-line').css('width', '375px');
            } else if ($('.sr-creg-step-city').parent('li:not(.hidden)').length === 4) {
                $('.connecting-line').css('width', '550px');
            }

        });

        const userLang = navigator.language || navigator.userLanguage;
        if (!countrySelectField.val() && userLang.substring(0, 2) === "de") {
            countrySelectField.val(57);  // Preselect
        }
    }

    initializeFstateSelectElements(fstateArray) {
        let fstate = $("#sr_creg_fstate");
        fstateArray.forEach((item) => {
            const option = new Option(item, item);
            fstate.append(option);
        });
    }

    /** geprüft */
    initializeAgsSelectField(element, apiUrl, apiKey) {
        const agsRequestURL = apiUrl + 'city/ags_search?sr_api_key=' + apiKey + '&XDEBUG_SESSION=IDEA';
        element.select2({
            minimumInputLength: 3,
            ajax: {
                url: agsRequestURL,
                dataType: 'json',
                data: function(params) {
                    return {
                        sr_country_id: $('#sr_creg_country').val(),
                        sr_cityname: params.term,
                        sr_fstate: $('#sr_creg_fstate').val()
                    }
                },
                processResults: function(data) {
                    let resVal = [];
                    $.each(data.result, function(index,item) {
                        resVal.push({
                            'id' : item.uuid,
                            'text': item.cityname + " (" + item.ags + ")",
                            'ags': item.ags,
                            'city': item.cityname,
                        });
                    });
                    return {
                        results: resVal
                    }
                }
            }
        });
    }

    /** geprüft */
    initializeSelect2Fields(apiUrl, apiKey) {
        $('#sr_creg_citytype_preset').select2({
        });

        $('#sr_creg_country').select2({});

        this.initializeAgsSelectField($('.select2-kommune'), apiUrl, apiKey);

        $('#sr_creg_fstate').select2({});

        $('#sr_creg_contact_salutation').select2({});
        $('#sr_creg_coordination_salutation').select2({});
        $('#sr_creg_invoice_salutation').select2({});
        $('#sr_creg_radar_salutation').select2({});

        $('#sr_creg_contact_title').select2({});
        $('#sr_creg_coordination_title').select2({});
        $('#sr_creg_invoice_title').select2({});
        $('#sr_creg_radar_title').select2({});

        // Workaround 2021-03-10 for select2 not initializing correctly
        setTimeout(function() {
            $('#sr_creg_coordination_salutation').select2({});
            $('#sr_creg_coordination_title').select2({});
        }, 500);

    }

    async callAgsInfo(ags, registerMode, adminMode, kbMember, agfkMember, apiUrl, apiKey, tabOffset) {
        const formThis = this;
        const fstate_ids = {
            '01': 'Schleswig-Holstein',
            '02': 'Hamburg',
            '03': 'Niedersachsen',
            '04': 'Bremen',
            '05': 'Nordrhein-Westfalen',
            '06': 'Hessen',
            '07': 'Rheinland-Pfalz',
            '08': 'Baden-Württemberg',
            '09': 'Bayern',
            '10': 'Saarland',
            '11': 'Berlin',
            '12': 'Brandenburg',
            '13': 'Mecklenburg-Vorpommern',
            '14': 'Sachsen',
            '15': 'Sachsen-Anhalt',
            '16': 'Thüringen'
        }

        if (citySignUpConfig.g_agsInfoAJAXRequest !== false) {
            citySignUpConfig.g_agsInfoAJAXRequest.abort();
            citySignUpConfig.g_agsInfoAJAXRequest = false;
        }

        if (registerMode || (citySignUpConfig.g_regIsInitialized && adminMode)) {
            if (ags) {
                citySignUpConfig.g_agsInfoAJAXRequest = $.ajax({
                    url: apiUrl + "city/ags_info?sr_ags=" + encodeURIComponent(ags) + "&sr_api_key=" + apiKey,
                    crossDomain: true,
                    xhrFields: {
                        withCredentials: true
                    },
                    dataType: "json",
                    success: async function (data) {
                        let country = $('#sr_creg_country');

                        let radarEnableWrapper = $('#sr_creg_admin_radar_wrapper');
                        let radarAlreadyExists = $('#sr_creg_radar_exist_wrapper');
                        let radarSection = $('#sr_creg_radar_section');
                        let radarEnableCheckbox = $('#sr_creg_radar_enabled');

                        if (data['communeGotContract']) {
                            radarEnableWrapper.addClass('hidden');
                            radarAlreadyExists.removeClass('hidden');
                            radarSection.addClass('hidden');
                            radarEnableCheckbox.prop('checked', false);
                        } else {
                            radarAlreadyExists.addClass('hidden');
                            radarEnableWrapper.removeClass('hidden');
                        }

                        if (typeof data.found === "undefined" || !data.found) {
                            // Remove AGFK checkmark outside germany (since field is hidden anyway)
                            if (country.val() !== 57) {
                                agfkMember.removeAttr("checked").change();
                            }
                            return;
                        }
                        if (data.is_kb_member) {
                            kbMember.prop("checked", "checked").change();
                        } else {
                            kbMember.removeAttr("checked").change();
                        }

                        if (data.is_agfk_member) {
                            agfkMember.prop("checked", "checked").change();
                        } else {
                            agfkMember.removeAttr("checked").change();
                        }

                        if (tabOffset === 0) {
                            if (data.ags && adminMode === 0 && country.val() === "57") {
                                const fstate_id = data.ags.substring(0, 2);
                                $('#sr_creg_fstate').val(fstate_ids[fstate_id]).change();
                            }
                        }

                        const blacklistedStatus = await formThis.getBlacklistedStatus(apiUrl, apiKey, data.uuid);
                        if (blacklistedStatus) {
                            let text = formThis.translationData.blacklistedCity;
                            const utility = new Utility();
                            utility.showPermanentErrorModal($('#sr-permanent-error-modal'), $('#sr-permanent-error-modal .sr-error-message-text'), text);
                        }

                    },
                    error: function () {
                        console.log("AGS info error");
                    }
                });
            }
        }
    }

    async getBlacklistedStatus(apiUrl, apiKey, uuid) {
        let status = false;
        await $.ajax({
            url: apiUrl + 'ags/blacklist/get?sr_api_key=' + apiKey + '&XDEBUG_SESSION=IDEA',
            method: 'POST',
            data: {
                'uuid': uuid
            },
            crossDomain: true,
            xhrFields: {
                withCredentials: true
            },
            dataType: "json",
            success: function (data) {
                status = data;
            }
        });

        return status;
    }

    /** geprüft */
    initializeEventListenerAgs(citySignupForm, registerMode, adminMode, apiUrl, apiKey, tabOffset = 0) {

        let ags = $("#sr_creg_ags");
        let country = $("#sr_creg_country");
        let kbMember = $("#sr_creg_is_kb_member");
        let agfkMember = $("#sr_creg_is_agfk_member");
        let cityname = $("sr_creg_cityname");
        let form = this;

        if (tabOffset !== 0) {
            ags = $("#sr_creg_ags_" + tabOffset);
            country = $("#sr_creg_country_" + tabOffset);
            kbMember = $("#sr_creg_is_kb_member_" + tabOffset);
            agfkMember = $("#sr_creg_is_agfk_member_" + tabOffset);
            cityname = $("sr_creg_cityname_" + tabOffset);
        }

        ags.change(function () {
            form.callAgsInfo($(this).val(), registerMode, adminMode, kbMember, agfkMember, apiUrl, apiKey, tabOffset);
        });

        cityname.off('change').on('change', (e) => {
            citySignupForm.autodetectAGS(e.currentTarget, $('#sr_kommunenregistrierung_main input[name=sr_creg_ags]'));
        })
    }

    /** geprüft */
    setCityOnSelectChange(citySignupForm, data, translationData) {
        // Taking over Name and AGS Data from "Kommune suchen" - Field to Cityname and AGS-Field
        $('#sr_creg_ags_search_main').on('select2:select', function(e) {
            $('#sr_creg_cityname').val(e.params.data.city).change();
            $('#sr_creg_uuid').val(e.params.data.id);
            $('#sr_creg_ags').val(e.params.data.ags).change();

            // Recalculate special cases
            const citySignupShoppingBasket = new CitySignupShoppingBasketInit(citySignupForm, data, translationData);
            citySignupShoppingBasket.citySignupShoppingBasketUpdate.update_basket_special_cases();

        });
    }

    /** geprüft */
    triggerChangeEvents() {
        $("#sr_creg_country").change();
        $("#sr_creg_fstate").change();
        $("#sr_creg_citytype_preset").change();
        $("#sr_creg_ownDescription_article").change();
        $('#sr_creg_contact_salutation').change();
        $('#sr_creg_coordination_salutation').change();
        $('#sr_creg_invoice_salutation').change();
        $('#sr_creg_radar_salutation').change();
        $(".sr_kommunenregistrierung_sub [name^=sr_creg_citytype_preset]").change();

        $("#sr_creg_coordination_enabled").trigger("change");
        $("#sr_creg_invoice_enabled").trigger("change");
        $("#sr_creg_radar_enabled").trigger("change");
        $("#sr_creg_radar_contact").trigger("change");
    }

    /** geprüft */
    initializeEventListenerDate(adminMode, minDate, maxDate, tddStartDate, radarMinDate, radarMaxDate, citySignupForm, citySignUpValidation) {
        let startDateSelector = $("#sr_creg_start_date");

        // #2638 Don't show past dates in datepicker
        let startDate = new Date(minDate + "T00:00:00");
        let endDate = new Date(maxDate + "T00:00:00");
        let curDate   = new Date();

        startDateSelector.datepicker("destroy").datepicker({
            changeMonth: true,
            changeYear: true,
            format: "dd.mm.yyyy",
            daysOfWeekHighlighted: "6,0",
            language:   "de",
            calenderWeeks: true,
            startDate:  startDate,
            endDate: endDate,
            beforeShowDay: function(d) {
                if ($("#sr_creg_country").val() === 134) {  // Luxembourg
                    // Only allow TDD start date
                    let tdd_date = new Date(tddStartDate + "T00:00:00");
                    return typeof d !== "undefined" && d && d.getFullYear() === tdd_date.getFullYear() && d.getMonth() === tdd_date.getMonth() && d.getDate() === tdd_date.getDate();
                } else {
                    return true;
                }
            }
        }).change(function() {
            citySignupForm.update_enddate();
        });
        citySignupForm.check_datepicker(startDateSelector);

        this.initializeRadarDatepicker(radarMinDate, radarMaxDate, citySignupForm, citySignUpValidation);

        // #2983
        // LU cities might restore data with old (invalid) start dates, which override the date which was set automatically
        // Workaround: Just explicitly set the date again at end of reg
        if ($("#sr_creg_country").val() === 134 && !startDateSelector.val()) {   // Luxembourg
            // Fixed dates in LU
            startDateSelector.datepicker("setDate", new Date(tddStartDate + "T00:00:00")).datepicker("update");
            citySignupForm.update_enddate();
            startDateSelector.attr("readonly", "readonly");
        }
    }

    /** geprüft */
    initializeRadarDatepicker(radarMinDate, radarMaxDate, citySignupForm, citySignUpValidation, tabOffset = 0) {
        let radarStartDateSelector = $("#sr_creg_radar_start_date");
        let radarEndDateSelector = $("#sr_creg_radar_end_date");
        let radarEnabled = $("#sr_creg_radar_enabled");
        let radarContact = $("#sr_creg_radar_contact");

        if (tabOffset !== 0) {
            radarStartDateSelector = $("#sr_creg_radar_start_date_" + tabOffset);
            radarEndDateSelector = $("#sr_creg_radar_end_date_" + tabOffset);
            radarEnabled = $("#sr_creg_radar_enabled_" + tabOffset);
            radarContact = $("#sr_creg_radar_contact_" + tabOffset);
        }

        radarStartDateSelector.datepicker("destroy").datepicker({
            changeMonth: true,
            changeYear: true,
            format: "dd.mm.yyyy",
            daysOfWeekHighlighted: "6,0",
            language:   "de",
            calenderWeeks: true,
            startDate:  new Date(radarMinDate + "T00:00:00"),
            endDate:    new Date(radarMaxDate + "T00:00:00"),
            onSelect:   function() {
                citySignupForm.update_radar_end_date(tabOffset);
                citySignUpValidation.validate_radar_date_range(tabOffset);
            }
        }).change(function() {
            citySignupForm.update_radar_end_date(tabOffset);
            citySignUpValidation.validate_radar_date_range(tabOffset);
        }).datepicker("update");
        citySignupForm.check_datepicker(radarStartDateSelector);

        radarEndDateSelector.datepicker("destroy").datepicker({
            changeMonth: true,
            changeYear: true,
            format: "dd.mm.yyyy",
            daysOfWeekHighlighted: "6,0",
            language:   "de",
            calenderWeeks: true,
            startDate:  new Date(radarMinDate + "T00:00:00"),
            endDate:    new Date(radarMaxDate + "T00:00:00"),
            onSelect:   function() {
                citySignUpValidation.validate_radar_date_range(tabOffset);
            }
        }).change(function() {
            citySignUpValidation.validate_radar_date_range(tabOffset);
        }).datepicker("update");
        citySignupForm.check_datepicker(radarEndDateSelector);

        radarStartDateSelector.off('change').on('change', () => {
            citySignUpValidation.validate_radar_date_range(tabOffset);
        });
        radarEndDateSelector.off('change').on('change', () => {
            citySignUpValidation.validate_radar_date_range(tabOffset);
        });

        radarEnabled.trigger("change");
        radarContact.trigger("change");
    }

    /** geprüft */
    initializeSubCityEventListener(citySignupForm, citySignupAddSubCity) {

        // add first subcity formular to accordeon
        const subCityAccordeonContent = $('#subcity-new-content-wrapper .accordion-template .akkordeon-topic-body .akkordeon-content');
        citySignupAddSubCity.addInitialSubCityHtml(subCityAccordeonContent, citySignupForm);

        // add sub city - with new design
        const addSubCity = $('.addSubCity');
        addSubCity.off('click').on('click', (event) => {
            citySignupAddSubCity.checkOnMandatoryFieldsForSubCities(event.target.dataset.srOffset, citySignupForm);
        });

    }

    /** geprüft */
    preselectLuxCounty(preselectValue) {
        if (preselectValue) {
            $('#sr_creg_country').val(134);
            setTimeout(function() { $('#sr_creg_country').change(); }, 1000);
        }
    }

    avoidChangeSite(translationData) {
        // Backspace für Navigation unterbinden
        let rx = /INPUT|SELECT|TEXTAREA/i;

        $(document).bind("keydown keypress", function (e) {
            if (e.which === 8) {     // Test auf Backspace
                if (!rx.test(e.target.tagName) || e.target.disabled || e.target.readOnly) {
                    e.preventDefault();
                }
            }
        });

        window.onbeforeunload = function () {
            return translationData.deleteOfEntries
        };
    }

    /** geprüft */
    checkLoadingPreviousData(loadData, citySignupForm, data, showLoadPreYear, citySignupAddSubCity) {

        if (showLoadPreYear !== 1) {
            $('.commune-registered').addClass('hide');
        }
        if (loadData !== "0") {
            citySignupForm.load_form(data, citySignupAddSubCity);

            if (citySignUpConfig.g_forceRecalculate !== 0) {
                citySignupForm.citySignupShoppingBasketInit.citySignupShoppingBasketUpdate.update_basket_attendence($('#sr_creg_population'),'attendence');

                // Update shopping basket with merchandise
                $('#sr_creg_merchandise_1').change();
                $('#sr_creg_merchandise_3').change();
                $('#sr_creg_merchandise_4').change();
                $('#sr_creg_merchandise_6').change();
                $('#sr_creg_merchandise_7').change();
                $('#sr_creg_merchandise_8').change();

                // #5209 Force recalculate based on country
                setTimeout(function() {
                    $("#sr_creg_country").change();
                }, 500);
            }
        }

        // If the user is getting data from pre year
        const urlParams = new URLSearchParams(window.location.search);
        if (urlParams.get("sr_token") !== null) {
            //$("#sr_creg_ags").change(); #7226
            $('.table-abg').find("input").each((index, item) => {
                $(item).prop("checked", false);
            });
        }
    }

    /** geprüft */
    initializeSubmitButtons(citySignupForm, citySignupValidation, translationData, data) {
        const citySignupSubmitForm = new CitySignupSubmitForm(citySignupForm, citySignupValidation, translationData, data);
        const submitButtonWrapper = $('#submitButtonWrapper');
        const saveButtonWrapper = $('.saveButtonWrapper');
        const utility = new Utility();
        let sr_creg_id = utility.getUrlParameter('sr_creg_id');
        let sr_pre_load = utility.getUrlParameter('sr_token');
        let saveButton = "";

        if (!sr_creg_id) {
            sr_creg_id = utility.getUrlParameter('sr_temp_creg_id');
        }

        if(!sr_pre_load) {
            sr_pre_load = utility.getUrlParameter('sr_hash');
        }

        if (data.adminMode === 1 && data.showAdminSaveButtons === 1) {
            if (data.returnMode === "wrw") {
                saveButton = "<a style='width:150px;' class='buttons citySignupBackButton' onClick='window.onbeforeunload = null; return true;' href='" + data.linkWrw + "'>" + translationData.backToOverview + "</a>";
            } else {
                saveButton = "<a style='width:150px;' class='buttons citySignupBackButton' onClick='window.onbeforeunload = null; return true;' href='" + data.linkReg + "'>" + translationData.backToOverview + "</a>";
            }
            saveButton += "<a style='width:150px;' class='buttons citySignupSubmitSave' href='#'>" + translationData.save + "</a>";
            saveButton += "<a style='width:150px;' class='buttons edit_button' href='#'>" + translationData.edit + "</a>";

            saveButtonWrapper.each((index, item) => {
                $(item).html(saveButton);
            });
        }

        if (data.adminMode === 1 && data.published === 0 && !sr_creg_id && !sr_pre_load) {
            //clean admin
            submitButtonWrapper.append("<a class='btn btn-default button-before hidden' id='toBeforeStep' href='#'>" + translationData.backLink + "</a>");
            submitButtonWrapper.append("<a class='btn btn-default button-save' href='#'>" + translationData.tempSaveCity + "</a>");
            submitButtonWrapper.append("<a class='btn btn-primary' id='toNextStep' href='#'>" + translationData.nextLink + "</a>");
            submitButtonWrapper.append("<a class='button-submit hidden' id='finalSubmitButton' data-sr-submit='register' href='#'>" + translationData.registerCity + "</a>");
        } else if (data.adminMode === 1 && data.published === 0 && sr_creg_id && sr_pre_load) {
            //load data admin
            submitButtonWrapper.append("<a class='btn btn-default button-before hidden' id='toBeforeStep' href='#'>" + translationData.backLink + "</a>");
            submitButtonWrapper.append("<a class='btn btn-default button-save' href='#'>" + translationData.tempSaveCity + "</a>");
            submitButtonWrapper.append("<a class='btn btn-primary' id='toNextStep' href='#'>" + translationData.nextLink + "</a>");
            submitButtonWrapper.append("<a class='button-submit hidden' id='finalSubmitButton' data-sr-submit='register' href='#'>" + translationData.registerCity + "</a>");
        } else if (data.adminMode === 1 && data.published === 0 && sr_creg_id && !sr_pre_load) {
            //  nicht freigeschaltet admin
            submitButtonWrapper.append("<a class='btn btn-default button-before hidden' id='toBeforeStep' href='#'>" + translationData.backLink + "</a>");
            submitButtonWrapper.append("<a class='btn btn-default button-save' href='#'>" + translationData.tempSaveCity + "</a>");
            //submitButtonWrapper.append(saveButton);
            submitButtonWrapper.append("<a class='btn btn-primary' id='toNextStep' href='#'>" + translationData.nextLink + "</a>");
            submitButtonWrapper.append("<a class='button_big_c hidden' onClick='window.onbeforeunload = null; return true;' href='#' id='finalSubmitButton' data-sr-submit='update'>" + translationData.activateCity + "</a>");
        } else if (data.adminMode === 1 && data.published === 1 && sr_creg_id && !sr_pre_load) {
            // freigeschaltet admin
            submitButtonWrapper.append("<a class='btn btn-default button-before hidden' id='toBeforeStep' href='#'>" + translationData.backLink + "</a>");
            submitButtonWrapper.append("<a class='btn btn-default button-save' href='#'>" + translationData.tempSaveCity + "</a>");
            //submitButtonWrapper.append(saveButton);
            submitButtonWrapper.append("<a class='btn btn-primary' id='toNextStep' href='#'>" + translationData.nextLink + "</a>");
            submitButtonWrapper.append("<a class='button_big_c hidden' onClick='window.onbeforeunload = null; return true;' href='#' id='finalSubmitButton' data-sr-submit='update'>" + translationData.refreshCity + "</a>");
        } else {
            // clean user
            // load data user
            submitButtonWrapper.append("<a class='btn btn-default button-before hidden' id='toBeforeStep' href='#'>" + translationData.backLink + "</a>");
            submitButtonWrapper.append("<a class='btn btn-default button-save' href='#'>" + translationData.tempSaveCity + "</a>");
            submitButtonWrapper.append("<a class='btn btn-primary' id='toNextStep' href='#'>" + translationData.nextLink + "</a>");
            submitButtonWrapper.append("<a class='button-submit hidden' id='finalSubmitButton' data-sr-submit='register' href='#'>" + translationData.registerCity + "</a>");
        }

        $('.button-save').on('click', (e) => {
            e.preventDefault();
            citySignupSubmitForm.citySignupSaveTemporary("save_temp");
        })

        $('#finalSubmitButton[data-sr-submit=register]').on('click', (e) => {
            e.preventDefault();
            citySignupSubmitForm.citySignupSubmitPreparation("register");
        });

        $('.citySignupSubmitSave').each((index, item) => {
            $(item).off('click').on('click', (e) => {
                e.preventDefault();
                citySignupSubmitForm.citySignupSubmitPreparation("save");
            });
        });

        $('.edit_button').each((index, item) => {
            $(item).off('click').on('click', (e) => {
                e.preventDefault();
                citySignupSubmitForm.citySignupEdit();
            });
        });

        $('#finalSubmitButton[data-sr-submit=update]').on('click', (e) => {
            e.preventDefault();
            citySignupSubmitForm.citySignupSubmitPreparation("create");
        })
    }

    insertOrderConfirmationDetails(shoppingBasketValues) {
        const attendanceFee = document.querySelector('#order-confirmation-attendance-fee');
        const lkAttendanceWrapper = document.querySelector('#order-confirmation-subcity-wrapper');
        const additionalText = document.querySelector('#order-confirmation-additional-text');
        const promotion = document.querySelector('#attendence-info-price').dataset.srPromotion;
        const promotionWrapper = document.querySelector('#order-confirmation-promotion-wrapper');
        const promotionValue = document.querySelector('#order-confirmation-promotion-value');
        const radarWrapper = document.querySelectorAll('.order-confirmation-radar-wrapper');
        const radarLabel = document.querySelector('#order-confirmation-radar-label');
        const radarPrice = document.querySelector('#order-confirmation-radar-price');
        const merchWrapper = document.querySelector('#order-confirmation-merch-wrapper');
        const merchList = document.querySelector('#order-confirmation-merch-list');
        const netPrice = document.querySelector('#order-confirmation-net-price');
        const grossPrice = document.querySelector('#order-condirmation-gross-price');

        let kbLabel = "";

        /* Einfügen der Teilnahmegebühr */
        if (shoppingBasketValues.price) {
            attendanceFee.innerHTML = srCurrencyNumberFormat((shoppingBasketValues.price/100), '€');

            /* Einfügen der Zusatzbeschreibung */
            additionalText.innerHTML = shoppingBasketValues.label;
        }

        /* Einfügen der zugehörigen Kommunen*/
        lkAttendanceWrapper.innerHTML = "";
        if (shoppingBasketValues.lk_attendence) {
            let html = "";
            Object.values(shoppingBasketValues.lk_attendence).forEach(item => {
                if (item.is_kb_member) {
                    item.cityName = item.cityName + "<br>Klima-Bündnis-Mitglied";
                }
                html += "<div class='order-confirmation-subcity-item d-flex justify-between'>";
                html += "<div class='order-confirmation-subcity-label'>" + item.counter + ". " + item.cityName + "</div>";
                html += "<div class='order-confirmation-subcity-price'>" + srCurrencyNumberFormat((item.price/100), '€') + "</div>";
                html += "</div>";
            });

            $(lkAttendanceWrapper).append(html);
        }

        /* Einfügen der Förderung */
        if (parseFloat(promotion) !== 0) {
            promotionWrapper.classList.remove('hidden');
            promotionValue.innerHTML = "- " + srCurrencyNumberFormat((promotion/100), '€');
        } else {
            promotionWrapper.classList.add('hidden');
        }

        /* Einfügen von Radar Details */
        if (shoppingBasketValues.radar) {
            radarWrapper.forEach((item) => {
                item.classList.remove('hidden');
            });
            radarLabel.innerHTML = shoppingBasketValues.radar.label;
            radarPrice.innerHTML = srCurrencyNumberFormat((shoppingBasketValues.radar.price/100), '€');
        } else {
            radarWrapper.forEach((item) => {
                item.classList.add('hidden');
            });
        }

        /* Einfügen von Werbematerialien */
        merchList.innerHTML = "";

        if (shoppingBasketValues.merchendise) {
            merchWrapper.classList.remove('hidden');

            let html = "<div class='order-confirmation-merch-list-wrapper'>";

            Object.values(shoppingBasketValues.merchendise).forEach(item => {
                html += "<div class='order-confirmation-merch-item-wrapper d-flex justify-between'>";

                html += "<div class='order-confirmation-merch-item-description'>" + item.amount + "x " + item.label + "</div>";

                html += "<div class='order-confirmation-merch-item-price'>" + srCurrencyNumberFormat((item.price/100), '€') + " </div>";
                html += "</div>";
            });
            html += "</div>";
            $(merchList).append(html);

        } else {
            merchWrapper.classList.add('hidden');
        }

        /* Einfügen des Gesamtpreises */
        let total_price = 0;
        let promotion_fund;

        $(".shopping-basket").find('.price-list').each(function() {
            let item_price = parseFloat($(this).attr('data-sr-json'))
            total_price = parseFloat(total_price) + parseFloat(item_price);
        });

        // reducing total price with promotionFund
        promotion_fund = $('#attendence-info-price').attr("data-sr-promotion");

        total_price = total_price - promotion_fund;

        netPrice.innerHTML = srCurrencyNumberFormat((total_price/100) * 100 / 119, '€');
        grossPrice.innerHTML = srCurrencyNumberFormat((total_price/100), '€');
    }

    initializeEventListenerForStepChange(citySignupValidation, data) {
        const wizardLinks = document.querySelectorAll('.sr-creg-step-city.btn-primary');
        wizardLinks.forEach((item) => {
            item.parentElement.addEventListener('click', () => {
                const beforeStepButton = document.querySelector('#toBeforeStep');
                const clickedStep = item.getAttribute('data-step');
                const allSteps = document.querySelectorAll('.container-height');
                const allWizardSteps = document.querySelectorAll('.sr-creg-step-city');
                const preyearWrapper = document.querySelector('#sr_prevyear_feedback_wrapper');

                allWizardSteps.forEach((wizardItem) => {
                    wizardItem.parentElement.classList.remove('active');
                });

                allSteps.forEach((item) => {
                    let step = parseInt(item.getAttribute('data-step'));

                    if (step === parseInt(clickedStep)) {
                        item.classList.remove('hidden');
                    } else {
                        item.classList.add('hidden');
                    }

                    if (parseInt(clickedStep) === 1) {
                        beforeStepButton.classList.add('hidden');
                        preyearWrapper.classList.remove('hidden');
                    } else {
                        beforeStepButton.classList.remove('hidden');
                        preyearWrapper.classList.add('hidden');
                    }
                });
                item.parentElement.classList.add('active');

                this.handlePageChange(citySignupValidation, data, false, true);
            });
        });
    }

    initializeStepWizard(citySignupForm, citySignupValidation, translationData, data) {
        let nextStepButton = document.querySelector('#toNextStep');
        let beforeStepButton = document.querySelector('#toBeforeStep');

        nextStepButton.addEventListener('click', () => {
            let currentStep = document.querySelector('.step-city:not(.hidden)').getAttribute('data-step');

            // create array with all missing fields => key is the number of the site
            mandatoryFieldArray[currentStep] = citySignupValidation.getMissingMandatoryFieldItems();
            const mandatoryFieldModal = document.querySelector('#sr-creg-mandatory-field');

            if (parseFloat(currentStep) === 2) {
                const citySignupAddSubcity = new CitySignupAddSubCity(citySignupForm.data, citySignupForm.translationData);
                citySignupAddSubcity.checkSingleSubCitiesOnMandatoryFields(citySignupForm, true);
            } else {
                // if in the array are missing fields on the site, the modal should be shown, otherwise the site changes
                if ($(mandatoryFieldArray[currentStep]).length > 0) {

                    $(mandatoryFieldModal).modal('show');
                    $('#sr-creg-mandatory-field-abort').off('click').on('click', () => {
                        $(mandatoryFieldArray[currentStep])[0].item.scrollIntoView({behavior: "smooth", block: "center", inline: "center"});
                    });
                    $('#sr-creg-mandatory-field-finish').off('click').on('click', () => {
                        // different layout of submit buttons
                        this.handlePageChange(citySignupValidation, data);
                    });
                } else {
                    this.handlePageChange(citySignupValidation, data);
                }
            }

            // check if there is a missing field - if yes then show the "!" in the step wizzard
            for (let key in mandatoryFieldArray) {
                const step = document.querySelector('.sr-creg-step-city[data-step="' + key + '"]');
                if (mandatoryFieldArray[key].length > 0) {
                    step.querySelector('.missing-field-info').classList.remove('hidden');
                } else {
                    step.querySelector('.missing-field-info').classList.add('hidden');
                }
            }

            this.initializeEventListenerForStepChange(citySignupValidation, data);
        });

        beforeStepButton.addEventListener('click', (event) => {
            const allSteps = document.querySelectorAll('.step-city')
            let currentStep = document.querySelector('.step-city:not(.hidden)').getAttribute('data-step');
            const allWizardSteps = document.querySelectorAll('.sr-creg-step-city');
            const selectedCityType = document.querySelector('#sr_creg_citytype_preset').value;
            const shoppingBasket = document.querySelector('.commune-pricing');
            let finalSubmitButton = document.querySelector('#finalSubmitButton');
            const selectedCountry = document.querySelector('#sr_creg_country').value;
            const preyearWrapper = document.querySelector('#sr_prevyear_feedback_wrapper');

            if (data.adminMode === 1 && data.published === 1) {
                finalSubmitButton = document.querySelector('#citySignupSubmitActivate');
            }

            if (finalSubmitButton) {
                finalSubmitButton.classList.add('hidden');
            }

            if (selectedCountry === "57") {
                // Wenn Typ nicht LK ist und current step = 3 ist, dann soll current step = 2 sein
                if (data.cityTypesSubCity.indexOf(selectedCityType) === -1 && parseInt(currentStep) - 1 === 2) {
                    // Wenn Typ nicht LK ist und current step = 3 ist, dann soll current step = 2 sein
                    currentStep = parseInt(currentStep) - 1;
                }
            } else {
                if (data.cityTypesSubCity.indexOf(selectedCityType) === -1 && parseInt(currentStep) === 4) {
                    // Wenn Ausland und current step = 4 ist und Typ nicht LK ist, dann soll current step = 2 sein
                    currentStep = parseInt(currentStep) - 2;
                } else if (data.cityTypesSubCity.indexOf(selectedCityType) !== -1 && parseInt(currentStep) === 4) {
                    // Wenn Ausland und current step = 4 ist und Typ LK ist, dann soll curren step = 3 sein
                    currentStep = parseInt(currentStep) - 1;
                }
            }

            allSteps.forEach((item) => {
                let step = parseInt(item.getAttribute('data-step'));
                let nextStep = parseInt(currentStep) - 1;

                if (step === nextStep) {
                    item.classList.remove('hidden');
                } else {
                    item.classList.add('hidden');
                }

                if (nextStep === 1) {
                    beforeStepButton.classList.add('hidden');
                    preyearWrapper.classList.remove('hidden');
                } else {
                    beforeStepButton.classList.remove('hidden');
                    preyearWrapper.classList.add('hidden');
                }
            });

            allWizardSteps.forEach((item) => {
                let step = parseInt(item.getAttribute('data-step'));
                let nextStep = parseInt(currentStep) - 1;

                if (step === nextStep) {
                    item.parentElement.classList.add('active');
                }

                if (step <= nextStep) {
                    item.classList.remove('btn-default');
                    item.classList.remove('disabled');
                    item.classList.add('btn-primary');
                }
            });

            currentStep = document.querySelector('.step-city:not(.hidden)').getAttribute('data-step');
            if (parseInt(currentStep) === 4) {
                finalSubmitButton.classList.remove('hidden');
            } else {
                shoppingBasket.classList.remove('hidden');
                nextStepButton.classList.remove('hidden');
                document.querySelector('#toNextStep').innerHTML = "Weiter";
            }

            this.initializeEventListenerForStepChange(citySignupValidation, data);
        });
    }

    handlePageChange(citySignupValidation, data, mainButtons = true, wizardButtonClick = false) {
        let nextStepButton = document.querySelector('#toNextStep');
        let beforeStepButton = document.querySelector('#toBeforeStep');
        const allSteps = document.querySelectorAll('.step-city')
        let currentStep = document.querySelector('.step-city:not(.hidden)').getAttribute('data-step');
        const allWizardSteps = document.querySelectorAll('.sr-creg-step-city');
        const selectedCityType = document.querySelector('#sr_creg_citytype_preset').value;
        const shoppingBasket = document.querySelector('.commune-pricing');
        let finalSubmitButton = document.querySelector('#finalSubmitButton');
        let stepAdditionalValue = 1;
        const selectedCountry = document.querySelector('#sr_creg_country').value;
        const preyearWrapper = document.querySelector('#sr_prevyear_feedback_wrapper');

        if (mainButtons !== true) {
            stepAdditionalValue = 0;
        }

        if (parseInt(currentStep) + stepAdditionalValue === 4) {
            if (document.querySelector('#sr_creg_pricing_json').value) {
                const shoppingBasketValues = JSON.parse(document.querySelector('#sr_creg_pricing_json').value);
                this.insertOrderConfirmationDetails(shoppingBasketValues);
            }
            shoppingBasket.classList.add('hidden');
            finalSubmitButton.classList.remove('hidden');
            nextStepButton.classList.add('hidden');
        } else if (parseInt(currentStep) > 1) {
            shoppingBasket.classList.remove('hidden');
            beforeStepButton.classList.remove('hidden');
            finalSubmitButton.classList.add('hidden');
            nextStepButton.classList.remove('hidden');
        } else {
            shoppingBasket.classList.remove('hidden');
            nextStepButton.classList.remove('hidden');
            finalSubmitButton.classList.add('hidden');

        }

        // jump over the second step if city type is kreis or landkreis
        if (data.cityTypesSubCity.indexOf(selectedCityType) === -1 && parseInt(currentStep) + stepAdditionalValue === 2) {
            currentStep = parseInt(currentStep) + stepAdditionalValue;
        }

        if (!wizardButtonClick) {
            if (data.cityTypesSubCity.indexOf(selectedCityType) !== -1 && selectedCountry !== "57") {
                if (parseInt(currentStep) === 2) {
                    currentStep = parseInt(currentStep) + 1;
                }
            } else if (selectedCountry !== "57") {
                currentStep = parseInt(currentStep) + 1;
            }
        }

        allSteps.forEach((item) => {
            let step = parseInt(item.getAttribute('data-step'));
            let nextStep = parseInt(currentStep) + stepAdditionalValue;
            if (step === nextStep) {
                item.classList.remove('hidden');
                item.parentElement.classList.add('active');
            } else {
                item.classList.add('hidden');
            }

            if (nextStep === 1) {
                beforeStepButton.classList.add('hidden');
                preyearWrapper.classList.remove('hidden');
            } else {
                beforeStepButton.classList.remove('hidden');
                preyearWrapper.classList.add('hidden');
            }
        });

        if (!wizardButtonClick) {
            allWizardSteps.forEach((item) => {
                let step = parseInt(item.getAttribute('data-step'));
                let nextStep = parseInt(currentStep) + stepAdditionalValue;

                if (step === nextStep) {
                    item.parentElement.classList.add('active');
                }

                if (step <= nextStep) {
                    item.classList.remove('btn-default');
                    item.classList.remove('disabled');
                    item.classList.add('btn-primary');
                } else {
                    item.classList.add('btn-default');
                    item.classList.add('disabled');
                    item.classList.remove('btn-primary');
                }
            });
        }

        const newStep = document.querySelector('.step-city:not(.hidden)').getAttribute('data-step');

        if (selectedCountry != "57") {
            if (newStep === "4") {
                if (document.querySelector('#sr_creg_pricing_json').value) {
                    const shoppingBasketValues = JSON.parse(document.querySelector('#sr_creg_pricing_json').value);
                    this.insertOrderConfirmationDetails(shoppingBasketValues);
                }
                finalSubmitButton.classList.remove('hidden');
                nextStepButton.classList.add('hidden');
                shoppingBasket.classList.add('hidden');
            } else {
                finalSubmitButton.classList.add('hidden');
                nextStepButton.classList.remove('hidden');
                shoppingBasket.classList.remove('hidden');
            }
        }

        if (newStep === "4") {
            shoppingBasket.classList.add('hidden');
        } else {
            shoppingBasket.classList.remove('hidden');
        }

        citySignupValidation.implementEventListenerForCheckingMandatoryFields();
    }
}

window.CitySignupInitialization = CitySignupInitialization;
