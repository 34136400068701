/**
 * This class contains all methods which are responsible for updating the shopping Basket of the city signup form
 */

class CitySignupShoppingBasketUpdate {
    constructor(citySignupForm, shoppingBasketInit, translationData, data) {
        this.shoppingBasketInit = shoppingBasketInit;
        this.translationData = translationData;
        this.data = data;
        this.citySignupForm = citySignupForm;
        this.citySignupShoppingBasketCalculation = new CitySignupShoppingBasketCalculation(this.citySignupForm, translationData, data);

    }

    /**
     * update_basket_new        = This function is used if a new sub commune will be added
     * @param id                = element id of the trigger
     * @param basket_type       = name for the label of the shopping basket
     * @param preLoad
     */
    update_basket_new(id, basket_type, preLoad = false) {
        let data = this.shoppingBasketInit.initData();
        let lk_offset = id.data('srOffset');
        let lk_counter = lk_offset/100;
        let fstate = $('#sr_creg_fstate').val();
        let priceArray = this.data.pricingJsonLk_currentYear;
        let form = this;

        $.ajax({
            url:  form.data.apiUrl + "promotion/getPriceTables?sr_fstate=" + fstate + "&sr_api_key=" + form.data.apiKey,
            type: "GET",
            success: function (response) {
                priceArray = response['lk_pricing'];
            }
        }).then(() => {
            // if a sub-commune gets registrated after the main registration
            if (lk_offset === 9999) {
                lk_counter = 1;
            }

            let is_kb_member = $('#sr_creg_is_kb_member_' + lk_offset);
            let price_label;
            let price_population;
            let cityName = $('#sr_creg_cityname_' + lk_offset).val();

            if (is_kb_member.prop('checked') === true) {
                price_label = lk_counter + '. ' + this.translationData.subCity + '<br>' + this.translationData.kbMember;
                price_population = priceArray[2];
            } else {
                price_label = lk_counter + '. ' + this.translationData.subCity;
                price_population = priceArray[3];
            }

            // Update Basket
            $('#attendence-overview').append("<tr class='sub-commune' data-sr-offset='" + id.data('srOffset') + "' ><td class='price-label' data-sr-json='" + basket_type + "'>" + price_label + "</td><td class='price-list' data-sr-counter='" + lk_counter + "' data-sr-label='" + price_label + "' data-sr-json='" + price_population + "' data-sr-cityname='" + cityName +"'>" + srCurrencyNumberFormat((price_population/100), '€') + "</td></tr>");

            if (!preLoad) {
                this.citySignupShoppingBasketCalculation.ajaxCalculatePromotion(data.fstate, data.population);
                // Calculate total price in the basket
                this.citySignupShoppingBasketCalculation.calculateTotal();

                // Create JSON-File for saving the entries
                this.citySignupShoppingBasketCalculation.createBasketJSON();
            }

            // update the numbers of the shopping basket
            this.updateNumbersOfSubCitiesInShoppingBasket();
        });

    }

    /**
     * update_basket_special_cases = This function calls the special cases
     */
    update_basket_special_cases() {
        let data = this.shoppingBasketInit.initData();
        if (citySignUpConfig.g_update_basket_disabled === false) {
            if (data.sub_communes.length >= 10 && data.regio_key_main === this.data.agsKeyRuhr.toString()) {
                this.update_basket_lk_attendence_ruhr();
            } else {
                // if regio key changed after special case
                this.shoppingBasketInit.initTrigger();
                this.update_basket_attendence('','attendence');
                this.update_basket_lk_attendence('', 'lk_attendence');
            }
        }
        // Calculate total price in the basket
        this.citySignupShoppingBasketCalculation.calculateTotal();

    }

    /**
     * update_basket_new        = This function to calculate the attendence fee
     * @param id                = element id of the trigger
     * @param basket_type       = name for the label of the shopping basket
     * @param basket_json       = json will be delivered if the form will be loaded
     * @param originData        = full data json of all from fields
     */
    update_basket_attendence(id, basket_type, basket_json , originData ) {
        let data = this.shoppingBasketInit.initData();
        let price_population;
        let price_label;
        let is_kb_member = $("#sr_creg_is_kb_member").prop("checked");
        let counter_lk = $('.sr_kommunenregistrierung_sub').length;
        let fstate = $('#sr_creg_fstate').val();
        let country = $('#sr_creg_country').val();
        let priceArray = this.data.pricingJson_currentYear;
        let form = this;

        // get current pricing array
        $.ajax({
            url:  form.data.apiUrl + "promotion/getPriceTables?sr_fstate=" + fstate + "&sr_api_key=" + form.data.apiKey,
            type: "GET",
            success: function (response) {
                priceArray = response['pricing'];
            }
        }).then(() => {
            if (basket_json === undefined) {
                basket_json = null;
            }

            if (originData === undefined) {
                originData = null;
            }

            if (citySignUpConfig.g_update_basket_disabled === true) {
                if (basket_json) {
                    price_population = basket_json.price;
                    price_label = basket_json.label;
                    this.add_price_to_basket($('#attendence-overview'), price_label, price_population, data, originData, basket_type);
                }
            } else {
                $.each(priceArray, function (index, value) {
                    if (data.population >= value[1]) {
                        if (is_kb_member === true) {
                            price_population = value[2];
                            price_label = " >= " + value[1] + ' ' + form.translationData.population + "<br> " + form.translationData.kbMember;
                        } else {
                            price_population = value[3];
                            price_label = " >= " + value[1] + ' ' +  form.translationData.population;
                        }
                    }
                });


                // Temporary special case for "Metropolregion Ruhr" if something need to change make sure also change in the following functions:
                // - registrierung-kommunen.cart.php - calculateAttendenceFee()
                // - registrierung-kommunen.cart.php - calculateLkAttendenceFee()
                // - registrierung-kommunen-warenkorb.js - update_basket_attendence()
                // - registrierung-kommunen-warenkorb.js - update_basket_lk_attendence()

                if (data.regio_key_main === this.data.agsKeyRuhr.toString() && counter_lk >= 10) {
                    if (data.population >= 100000) {
                        price_label = form.translationData.populationOver100000;
                        price_population = 130000;
                    } else {
                        price_label = form.translationData.populationUnder100000;
                        price_population = 65000;
                    }
                }
                // --
                this.add_price_to_basket($('#attendence-overview'), price_label, price_population, data, originData, basket_type);
                // delete the attandence info message and recalculate total
                if (data.fstate === "-") {
                    $('#attendence-info').hide();
                    //Calculate the total price
                    this.citySignupShoppingBasketCalculation.ajaxCalculatePromotion(data.fstate, data.population);
                    data.fstate = "";
                }

                // Calculate the total price
                this.citySignupShoppingBasketCalculation.ajaxCalculatePromotion(data.fstate, data.population);
            }
        });

    }

    /**
     * This function add adjust the attendance price label and the attendance price in the shopping basket
     * @param selector          the element of the shopping basket
     * @param price_label       the label which should be written
     * @param price_population  the entered population
     * @param data              the data array of common data
     * @param originData
     * @param basket_type
     */
    add_price_to_basket(selector, price_label, price_population, data, originData, basket_type) {
        let selectorChild = selector.children();

        if (price_label && price_population) {

            if (data.population || originData !== null) {
                if (selectorChild.find("." + basket_type).length !== 0) {
                    selectorChild.find("." + basket_type + " .price-list").html(srCurrencyNumberFormat((price_population/100), '€'));
                    selectorChild.find("." + basket_type + " .price-label").html(price_label);
                    selectorChild.find("." + basket_type + " .price-list").attr('data-sr-json', price_population);
                    selectorChild.find("." + basket_type + " .price-list").attr('data-sr-label', price_label);
                } else {
                    $('.attend-label').show();
                    selector.append("<tr class='" + basket_type + "'><td class='price-label' data-sr-json='" + basket_type + "'>" + price_label + "</td><td class='price-list' data-sr-label='" + price_label + "' data-sr-json='"+ price_population + "'>" + srCurrencyNumberFormat((price_population/100), '€') + "</td></tr>");
                }
            } else {
                $('.attend-label').hide();
                selectorChild.find("." + basket_type).remove();
            }
        }


        // Calculate total price in the basket
        this.citySignupShoppingBasketCalculation.calculateTotal();

        // Create JSON-File for saving the entries
        this.citySignupShoppingBasketCalculation.createBasketJSON();
    }

    /**
     *  Temporary special case for "Metropolregion Ruhr" if something need to change make sure also change in the following functions:
     *  - registrierung-kommunen.cart.php - calculateAttendenceFee()
     *  - registrierung-kommunen.cart.php - calculateLkAttendenceFee()
     *  - registrierung-kommunen-warenkorb.js - update_basket_attendence()
     *  - registrierung-kommunen-warenkorb.js - update_basket_lk_attendence()
     */
    update_basket_lk_attendence_ruhr() {
        let data = this.shoppingBasketInit.initData();
        let counter = 1

        $("input[name='sr_creg_is_kb_member']").off('change');
        $.each(data.sub_communes, function(i, item) {
            counter = 1 + i;
            let label =  counter + '. ' + this.translationData.subCity;
            let selector = $('.sub-commune .price-list')[i];
            $('.sub-commune .price-label')[i].innerHTML = label;
            selector.setAttribute('data-sr-label', label);

            let population_sub = parseInt($(item).find('input[name="sr_creg_population"]').val());
            if (population_sub >= 100000) {
                selector.innerHTML = srCurrencyNumberFormat(1300, '€');
                selector.setAttribute('data-sr-json','130000');
            } else {
                selector.innerHTML = srCurrencyNumberFormat(650, '€');
                selector.setAttribute('data-sr-json','65000');
            }
        });

    }

    /**
     * update_basket_new        = This function to calculate the attendence fee of sub communes
     * @param id                = element id of the trigger
     * @param basket_type       = name for the label of the shopping basket
     * @param basket_json       = json will be delivered if the form will be loaded
     */
    update_basket_lk_attendence(id, basket_type, basket_json) {
        let data = this.shoppingBasketInit.initData();
        let price_population = 0;
        let price_label = "";
        let selector;
        let sr_offset;
        let fstate = $('#sr_creg_fstate').val();
        let priceArray = this.data.pricingJsonLk_currentYear;
        let form = this;

        $.ajax({
            url:  form.data.apiUrl + "promotion/getPriceTables?sr_fstate=" + fstate + "&sr_api_key=" + form.data.apiKey,
            type: "GET",
            success: function (response) {
                priceArray = response['lk_pricing'];
            }
        }).then(() => {
            if (basket_json === undefined) {
                basket_json = null;
            }

            if (citySignUpConfig.g_update_basket_disabled === true && citySignUpConfig.g_forceRecalculate !== 1) {

                if (basket_json) {
                    let counter = 1;
                    $.each(basket_json, function (i, item) {

                        if (item !== null) {
                            price_population = item.price;

                            $.each(priceArray['lk_pricing'], function (index, value) {
                                if ($("#sr_creg_is_kb_member_" + (sr_offset * 100)).is(':checked')) {
                                    price_population = value[2];
                                } else {
                                    price_population = value[3];
                                }
                            });

                            price_label = item.label;
                            sr_offset = counter;

                            let cityName = $('#sr_creg_cityname_' + (sr_offset * 100)).val();
                            if (item.cityName) {
                                cityName = item.cityName;
                            }

                            if (item.is_kb_member === true) {
                                price_label = sr_offset + '. ' + form.translationData.subCity + ' <br> ' +  form.translationData.kbMember;
                            } else {
                                price_label = sr_offset + '. ' + form.translationData.subCity;
                            }

                            selector = $('.sub-commune[data-sr-offset="' + sr_offset + '"]');

                            // update basket
                            $('#attendence-overview').append("<tr class='sub-commune' data-sr-offset='" + sr_offset + "' ><td class='price-label' data-sr-json='" + basket_type + "'>" + price_label + "</td><td class='price-list' data-sr-counter='" + sr_offset + "' data-sr-label='" + price_label + "' data-sr-json='" + price_population + "' data-sr-cityname='" + cityName +"'>" + srCurrencyNumberFormat((price_population / 100), '€') + "</td></tr>");
                            counter = counter + 1;
                        }
                    });
                }
            } else {
                if (id === 'remove') {
                    // Update the promotion value after removing a sub commune
                    this.citySignupShoppingBasketCalculation.ajaxCalculatePromotion(data.fstate, data.population);
                } else if (id !== '') {

                    this.update_lk_basket_display();

                    sr_offset = id.data('sr-offset');
                    let offset_counter = sr_offset / 100;
                    selector = $('#sr_creg_population_' + sr_offset).val();

                    //
                    // if a sub-commune gets registrated after the main registration
                    //
                    if ($('.sr_kommunenregistrierung_sub').data('sr-offset') === 9999) {
                        //data.population = 1;
                        offset_counter = 1;
                    }

                    // update the basket if there is already a sub commune
                    if (id.prop("checked") === true) {
                        price_population = priceArray[2];
                        price_label = offset_counter + '. ' + this.translationData.subCity + '<br>' + this.translationData.kbMember;
                    } else {
                        price_population = priceArray[3];
                        price_population = parseFloat(priceArray[3]).toFixed(2);
                        price_label = offset_counter + '. ' + form.translationData.subCity;
                    }

                    selector = $('.sub-commune[data-sr-offset="' + sr_offset + '"]');
                    let selectorPrice = selector.children('.price-list');
                    // update basket
                    selector.children('.price-label').html(price_label);
                    selectorPrice.html(srCurrencyNumberFormat((price_population/100), '€'));

                    selectorPrice.attr('data-sr-json', price_population);
                    selectorPrice.attr('data-sr-label', price_label);
                    selectorPrice.attr('data-sr-counter', offset_counter);
                    // Update the promotion value
                    this.citySignupShoppingBasketCalculation.ajaxCalculatePromotion(data.fstate, data.population);
                }
            }


            // Calculate total price in the basket
            this.citySignupShoppingBasketCalculation.calculateTotal();

            // Create JSON-File for saving the entries
            this.citySignupShoppingBasketCalculation.createBasketJSON();
        });
    }

    update_lk_basket_display() {
        let data = this.shoppingBasketInit.initData();
        let fstate = $('#sr_creg_fstate').val();
        let priceArray = this.data.pricingJsonLk_currentYear;
        let country = $('#sr_creg_country').val()
        let counter_lk;
        let form = this;

        $.ajax({
            url:  form.data.apiUrl + "promotion/getPriceTables?sr_fstate=" + fstate + "&sr_api_key=" + form.data.apiKey,
            type: "GET",
            success: function (response) {
                priceArray = response['lk_pricing'];
            }
        }).then(() => {
            $.each(data.sub_communes, function(i, item) {

                let selector = $('.sub-commune')[i];
                let selectorPrice = $('.sub-commune .price-list')[i];

                let sr_offset = selector.getAttribute('data-sr-offset');
                let selectorLabel = $('.sub-commune .price-label')[i];
                let is_kb_member = $('#sr_creg_is_kb_member_' + sr_offset);

                if (selector) {
                    counter_lk = i + 1;
                    selector.setAttribute('data-sr-offset',item.getAttribute('data-sr-offset'));

                    if (is_kb_member.prop('checked') === true) {
                        selectorLabel.innerHTML = counter_lk + '. ' + form.translationData.subCity + '<br>' +  form.translationData.kbMember;
                        selectorPrice.setAttribute('data-sr-label', counter_lk + '. ' + form.translationData.subCity + '<br>' + form.translationData.kbMember);

                        selectorPrice.setAttribute('data-sr-json', priceArray[2]);
                        selectorPrice.innerHTML = srCurrencyNumberFormat(priceArray[2] / 100 , '€');

                    } else {
                        selectorLabel.innerHTML = counter_lk + '. ' + form.translationData.subCity;
                        selectorPrice.setAttribute('data-sr-label', counter_lk + '. ' + form.translationData.subCity);

                        selectorPrice.setAttribute('data-sr-json', priceArray[3]);
                        selectorPrice.innerHTML = srCurrencyNumberFormat(priceArray[3] / 100 , '€');
                    }
                }
            });
        });

    }

    /**
     * update_basket_new        = This function to calculate the radar fee
     * @param id                = element id of the trigger
     * @param basket_type       = name for the label of the shopping basket
     * @param basket_json       = json will be delivered if the form will be loaded
     * @param originData        = full data json of all from fields
     */
    update_basket_radar_fee(id, basket_type, basket_json , originData) {
        let data = this.shoppingBasketInit.initData();
        let radar_price;
        let radar_label;
        let licence_radar_label;
        let licence_radar;
        let radar_item = $("input[data-sr-name='sr_creg_radar_duration']:checked");
        let radar_enabled_main = $(".container-main input[name='sr_creg_radar_enabled']:checked");
        let is_kb_member = $("#sr_creg_is_kb_member").prop("checked");
        let selector = $('#radar-overview');
        let selectorChildren = selector.children();
        let form = this;
        let radarStartDate = $("#sr_creg_radar_start_date");


        if (basket_json === undefined) {
            basket_json = null;
        }

        if (originData === undefined) {
            originData = null;
        }

        if(radarStartDate.val() === undefined) {
            radarStartDate = $("#sr_creg_radar_start_date_" + id.data('sr-offset'));
        }

        // only show radar fee if it is a new booking
        if (radarStartDate.val().trim()) {
            let startDate = new Date(radarStartDate.val().trim().replace(/(.*)\.(.*)\.(.*)/, '$3-$2-$1'));
            let currentDate = new Date(Date.now());
            if (startDate < currentDate) {
                $('.radar-label').hide();
                selector.children().find("." + basket_type).remove();

                // Calculate total price in the basket
                this.citySignupShoppingBasketCalculation.calculateTotal();

                // Create JSON-File for saving the entries
                this.citySignupShoppingBasketCalculation. createBasketJSON();

                return 0;
            }

        }

        if (citySignUpConfig.g_update_basket_disabled === true) {
            if (basket_json) {
                if ((data.population && radar_enabled_main.length !== 0) || (originData.sr_creg_population && (typeof originData.sr_creg_radar_enabled !== "undefined" && originData.sr_creg_radar_enabled.length !== 0))) {
                    radar_price = basket_json.price;
                    radar_label = basket_json.label;
                    licence_radar = basket_json.licence;

                    if (selectorChildren.find("." + basket_type).length !== 0) {
                        selectorChildren.find("." + basket_type + " .price-list").html(srCurrencyNumberFormat((radar_price/100), '€'));
                        selectorChildren.find("." + basket_type + " .price-label").html(radar_label);
                        selectorChildren.find("." + basket_type + " .price-list").attr('data-sr-json', radar_price);
                        selectorChildren.find("." + basket_type + " .price-list").attr('data-sr-label', radar_label);
                        selectorChildren.find("." + basket_type + " .price-list").attr('data-sr-licence', licence_radar);
                    } else {
                        $('.radar-label').show();
                        selector.append("<tr class='" + basket_type + "'><td class='price-label' data-sr-json='" + basket_type + "'>" + radar_label + "</td><td class='price-list' data-sr-licence='" + licence_radar + "' data-sr-label='" + radar_label + "' data-sr-json='"+ radar_price + "'>" + srCurrencyNumberFormat((radar_price/100), '€') + "</td></tr>");
                    }
                } else {
                    $('.radar-label').hide();
                    selector.children().find("." + basket_type).remove();
                }
            }
        } else {
            // Calculate basket if radar is used

            if (typeof id === 'object') {
                // getting the sub commune checkbox if radar from the main commune is disabled
                if (id.data('sr-offset') && radar_enabled_main.length === 0) {
                    radar_item = $("input[data-sr-name='sr_creg_radar_duration_" + id.data('sr-offset') + "']:checked");
                }
            }


            // If population is given and the radar in the main commune is enabeled, the calculation of the radar price starts
            // Values are :
            // 1 = price free - use radar only in the timespace
            // 1full = getting price from g_radar_pricing - using radar for one year
            // 3 = getting price from g_radar_pricing - using radar for three years
            if (data.population && radar_enabled_main.length !== 0) {
                radar_price = 0;
                if (radar_item.val() === "1") {
                    licence_radar = 0;
                    licence_radar_label = form.translationData.radarFreeLicence;
                    $.each(this.data.pricingRadar.one_year_licence, function(item, value) {
                        if (data.population >= value[1]) {
                            if (is_kb_member === true) {
                                radar_label = ">= " + value[1] + ' ' + form.translationData.population + "<br>" + form.translationData.kbMember
                            } else {
                                radar_label = ">= " + value[1] + ' ' + form.translationData.population;
                            }
                        }
                    });
                } else if (radar_item.val() === "1full") {
                    licence_radar = 1;
                    licence_radar_label = form.translationData.radarOneYearLicence;
                    $.each(this.data.pricingRadar.one_year_licence, function(item, value) {

                        if (data.population >= value[1]) {
                            if (is_kb_member === true) {
                                radar_price = value[2];
                                radar_label = ">= " + value[1] + ' ' + form.translationData.population + "<br>" + form.translationData.kbMember;
                            } else {
                                radar_price = value[3];
                                radar_label = ">= " + value[1] + ' ' + form.translationData.population;
                            }
                        }
                    });
                } else if (radar_item.val() === "3") {
                    licence_radar = 3;
                    licence_radar_label = form.translationData.radarThreeYearLicence;
                    $.each(this.data.pricingRadar.three_year_licence, function(item, value) {

                        if (data.population >= value[1]) {
                            if (is_kb_member === true) {
                                radar_price = value[2];
                                radar_label = ">= " + value[1] + ' ' + form.translationData.population + "<br>" + form.translationData.kbMember;
                            } else {
                                radar_price = value[3];
                                radar_label = ">= " + value[1] + ' ' + form.translationData.population;
                            }
                        }
                    });
                }

                // Update Basket
                radar_label = radar_label + "<br>" + licence_radar_label;

                if (selectorChildren.find("." + basket_type).length !== 0) {
                    selectorChildren.find("." + basket_type + " .price-list").html(srCurrencyNumberFormat((radar_price/100), '€'));
                    selectorChildren.find("." + basket_type + " .price-label").html(radar_label);
                    selectorChildren.find("." + basket_type + " .price-list").attr('data-sr-json', radar_price);
                    selectorChildren.find("." + basket_type + " .price-list").attr('data-sr-label', radar_label);
                    selectorChildren.find("." + basket_type + " .price-list").attr('data-sr-licence', licence_radar);
                } else {
                    $('.radar-label').show();
                    selector.append("<tr class='" + basket_type + "'><td class='price-label' data-sr-json='" + basket_type + "'>" + radar_label + "</td><td class='price-list' data-sr-licence='" + licence_radar + "' data-sr-label='" + radar_label + "' data-sr-json='"+ radar_price + "'>" + srCurrencyNumberFormat((radar_price/100), '€') + "</td></tr>");
                }
            } else {
                $('.radar-label').hide();
                selector.children().find("." + basket_type).remove();
            }
        }


        // Calculate total price in the basket
        this.citySignupShoppingBasketCalculation.calculateTotal();

        // Create JSON-File for saving the entries
        this.citySignupShoppingBasketCalculation.createBasketJSON();
    }

    /**
     * update_basket_new        = This function to calculate the merchandise costs
     * @param id                = element id of the trigger
     * @param basket_item       = name for the label of the shopping basket
     * @param basket_json       = json will be delivered if the form will be loaded
     */
    update_basket_merchandise(id, basket_item, basket_json) {
        let merch_amount;
        let merch_price = 0;
        let add_merch_art = false;
        let label;

        // Update basket if merchandise is given
        if (basket_json === undefined) {
            basket_json = null;
        }

        if (id) {
            const errorField = id.parents('tr').find('.sr_form_feedback');
            if (parseFloat(id.attr('data-min-order-amount')) > parseFloat(id.val()) && id.val() !== "0") {
                $(errorField).html(this.translationData.messageMinOrder);
                return;
            } else {
                $(errorField).html("");
            }
        }

        if (citySignUpConfig.g_update_basket_disabled === true || typeof id !== "object") {
            if (basket_json) {

                merch_price = basket_json.price;
                merch_amount = basket_json.amount;
                label = basket_json.label;

            }
        } else {

            label = id.data('label');

            // identifing price from merchandise table
            if (id.closest('table').attr('id') === "merchandise") {

                merch_price = parseInt(id.val()) * id.data('price');

                merch_amount = parseInt(id.val());
                add_merch_art = true;
            } else {
                merch_price = id.data('price');
                merch_amount = id.data('amount');
            }
        }

        // Update price in shopping basket
        let selector = $('#merchandise-overview');
        let selectorChildren = selector.children();

        // not using replaceAll, because IE11 is not supporting this
        let basket_item_class = basket_item.split(' ').join('');

        //var basket_item_class = basket_item.replaceAll(' ', '');

        if (merch_price !== '-') {
            // Check if additional merchandise article set to 0
            if ((merch_price === "0" || merch_price === 0) && add_merch_art === true) {
                selectorChildren.find("." + basket_item_class).remove();
            } else {
                if (selectorChildren.find("." + basket_item_class).length !== 0) {
                    selectorChildren.find("." + basket_item_class + " .price-label").html(label);
                    selectorChildren.find("." + basket_item_class + " .price-list").html(srCurrencyNumberFormat((merch_price/100), '€'));
                    selectorChildren.find("." + basket_item_class + " .price-list").attr('data-sr-json', merch_price);
                    selectorChildren.find("." + basket_item_class + " .price-list").attr('data-amount', merch_amount);
                    selectorChildren.find("." + basket_item_class + " .price-list").attr('data-sr-label', label);
                } else {
                    selector.append("<tr class='" + basket_item_class + "'><td class='price-label' data-sr-json='" + basket_item + "'>" + label + "</td><td class='price-list' data-amount='" + merch_amount +"' data-sr-label='" + label + "' data-sr-json="+ merch_price + ">" + srCurrencyNumberFormat((merch_price/100), '€') + "</td></tr>");
                }
            }
        } else {
            selectorChildren.find("." + basket_item).remove();
        }

        // Set or delete title "Werbematerialien"
        if (selectorChildren.find('.price-label').length > 0) {
            $('.merch-label').show();
        } else {
            $('.merch-label').hide();
        }


        // Calculate total price in the basket
        this.citySignupShoppingBasketCalculation.calculateTotal();

        // Create JSON-File for saving the entries
        this.citySignupShoppingBasketCalculation.createBasketJSON();
    }

    /**
     * update_basket_promotion        = This function get the saved promotion from the json file
     * @param basket_json             = json will be delivered if the form will be loaded
     */
    update_basket_promotion(basket_json) {
        if (basket_json) {
            let selectorInfo = $('#attendence-info');
            let selectorInfoPrice = $('#attendence-info-price');
            let promotionFund = basket_json.promotion;
            let attendenceMessage = this.translationData.promotionTitle;

            if (promotionFund > 0) {
                selectorInfo.show();
                selectorInfoPrice.html("- " + srCurrencyNumberFormat((promotionFund/100), '€'));
                selectorInfoPrice.attr('data-sr-promotion', promotionFund);
                selectorInfo.addClass("attendence-green");
                $('#attendence-info-message').html(attendenceMessage);
            } else {
                $('#shopping-list-info').hide();
                selectorInfo.hide();
                selectorInfoPrice.html("- " + srCurrencyNumberFormat((promotionFund / 100), '€'));
                selectorInfoPrice.attr('data-sr-promotion', promotionFund);
                selectorInfo.addClass("attendence-green");
                $('#attendence-info-message').html(attendenceMessage);
            }
        }
    }

    // Update the numbers of the sub cities in the shopping basket
    updateNumbersOfSubCitiesInShoppingBasket() {
        const subCommunes = document.querySelectorAll('#attendence-overview .sub-commune');

        subCommunes.forEach((item, index) => {
            const label = item.querySelector('.price-label');
            const labelWithoutNumber = label.innerHTML.split('.');
            const correctNumber = index + 1;

            // change visible numbers
            item.querySelector('.price-label').innerHTML = correctNumber + '. ' + labelWithoutNumber[1];

            // change data-sr-label
            item.querySelector('.price-list').setAttribute('data-sr-label', correctNumber + '. ' + labelWithoutNumber[1]);

            // change data counter
            item.querySelector('.price-list').setAttribute('data-sr-counter', correctNumber);
        });
    }

}

window.CitySignupShoppingBasketUpdate = CitySignupShoppingBasketUpdate;
