/**
 * This class is responsible for the calculation of the values of the shopping basket of the city signup form
 * Also it contains the creation of the shoppingBasket Object
 */

class CitySignupShoppingBasketCalculation {
    constructor(citySignupForm, translationData, data) {
        this.translationData = translationData;
        this.data = data;
        this.citySignupForm = citySignupForm;

    }
    /**
     * ajaxCalculatePromotion - Function to send an ajax request and calculate the promotion value based on the already entered data
     * @param fstate
     * @param population
     */
    ajaxCalculatePromotion(fstate, population) {
        let promotionFund;
        let attendenceMessage;
        let form = this;

        if (typeof population === 'undefined') {
            population = $("input[name='sr_creg_population']").val();
        }

        if (fstate && population && citySignUpConfig.g_update_basket_disabled === false) {

            $.ajax({
                url:  form.data.formUrl + "updateShoppingBasket&sr_returnmode=" + form.data.returnMode + "&XDEBUG_SESSION=IDEA",
                type: "POST",
                data: JSON.stringify( this.citySignupForm.serialize_all() ),
                complete: function () {
                    citySignUpConfig.g_formAJAXSaveRequest = false;
                },
                success: function (response) {
                    let result;
                    result = JSON.parse(response);
                    // implement calculatedPromotion into attendence info price
                    attendenceMessage = form.translationData.promotionTitle;
                    promotionFund = result.calculatedPromotion;
                    citySignUpConfig.g_creg_available_promotion = result.availablePromotion;
                    let selectorInfo = $('#attendence-info');
                    let selectorInfoPrice = $('#attendence-info-price');
                    if (promotionFund > 0) {
                        selectorInfo.show();
                        selectorInfoPrice.html("- " + srCurrencyNumberFormat((promotionFund/100), '€'));
                        selectorInfoPrice.attr('data-sr-promotion', promotionFund);
                        $('#attendence-info-message').html(attendenceMessage);

                        // implement attendence info message tooltip and text if there is less originPromotionFund
                        if (result.availablePromotion > promotionFund || result.availablePromotion === null || result.totalPromotion === -1 || result.availablePromotion === -1 || result.membershipPromotion === -1) {
                            selectorInfo.addClass("attendence-green");
                            $('#shopping-list-info').hide();
                        } else {
                            $('#shopping-list-info').show();
                            selectorInfo.addClass("attendence-orange");
                        }
                    } else {
                        $('#shopping-list-info').hide();
                        selectorInfo.hide();
                        selectorInfoPrice.html("- " + srCurrencyNumberFormat((promotionFund/100), '€'));
                        selectorInfoPrice.attr('data-sr-promotion', promotionFund);
                        $('#attendence-info-message').html(attendenceMessage);
                    }

                    // Special Case for 2022: if the promotion fund of Niedersachsen and Bayern will be 0, then the checkboxes should disappear
                    if (citySignUpConfig.g_creg_available_promotion <= 0) {
                        if ($('#sr_creg_fstate').val() === "Niedersachsen") {
                            $('#sr_creg_promotion_confirmation_niedersachsen').prop("checked", true);
                            $('.promotion_confirmation_niedersachsen').addClass("hidden");
                        } else if ($('#sr_creg_fstate').val() === "Bayern") {
                            $('#sr_creg_promotion_confirmation_bayern').prop("checked", true);
                            $('.promotion_confirmation_bayern').addClass("hidden");
                        }
                    }

                    // Update total
                    form.calculateTotal();
                },

                error: function (xhr, ajaxOptions, thrownError) {
                    if (!isNaN(xhr.responseText) && xhr.responseText) {
                        form.citySignupForm.citySignupValidation.showErrorMessage(2000, xhr.responseText);
                    } else {
                        form.citySignupForm.citySignupValidation.showErrorMessage(2000, form.translationData.promotionError);
                    }
                }
            });
        } else {
            $('#attendence-info').hide();
            $('#attendence-info-price').attr('data-sr-promotion', 0);
            $('#attendence-info-price').html("");
            form.calculateTotal();
        }
    }

    /**
     * calculateTotal - Function to loop through every price item in the shopping basket and calculating the total
     */
    calculateTotal() {

        // Update total price
        let total_price = 0;
        let promotion_fund;

        $(".shopping-basket").find('.price-list').each(function() {
            let item_price = parseFloat($(this).attr('data-sr-json'))
            total_price = parseFloat(total_price) + parseFloat(item_price);
        });

        // reducing total price with promotionFund
        promotion_fund = $('#attendence-info-price').attr("data-sr-promotion");

        total_price = total_price - promotion_fund;

        // Write total price in table
        let selector = $('#total-price');
        selector.html(srCurrencyNumberFormat((total_price/100), '€'));
        selector.attr('data-price', total_price);
    }

    /**
     * createBasketJSON - Function to create a JSON with the already entered data and attach it to the hidden input field "sr_creg_pricing_json"
     */
    createBasketJSON() {
        let basketObj = {};
        let lk_att_single = {};
        let lk_attendence = {};
        let merchandise_item = {};
        let merch_label;
        let merchandise = {};
        let radar = {};
        let radar_item = {};
        let is_kb_member_lk;
        let is_kb_member;
        let price = 0;
        let offset;
        let amount;
        let label;
        let cityName;
        let counter;
        let radar_label;
        let radar_price;
        let radar_licence;
        let promotion;
        let total;

        $('.price-list').each(function() {
            price = $(this).attr('data-sr-json');
            amount = $(this).attr('data-amount');
            label = $(this).attr('data-sr-label');
            cityName = $(this).attr('data-sr-cityname');
            counter = $(this).attr('data-sr-counter');
            is_kb_member = $("#sr_creg_is_kb_member").prop("checked");
            promotion = $("#attendence-info-price").attr('data-sr-promotion');
            total = $("#total-price").attr('data-price');

            if ($(this).parent().hasClass('sub-commune')) {
                offset = $(this).parent('.sub-commune').data('sr-offset');
                if (offset < 100) {
                    offset = offset * 100;
                }
                is_kb_member_lk = $("#sr_creg_is_kb_member_" + offset).prop("checked");

                //lk_att_single.push([price, is_kb_member_lk]);
                lk_att_single[offset] = {
                    'price': price,
                    'is_kb_member': is_kb_member_lk,
                    'label': label,
                    'cityName': cityName,
                    'counter': counter
                }

                lk_attendence = {
                    'lk_attendence': lk_att_single
                }

            } else if ($(this).parents('#merchandise-overview').length) {
                merch_label = $(this).prev().data('sr-json');
                merchandise_item[merch_label] = {
                    'price': price,
                    'amount': amount,
                    'label': label
                }
                merchandise = {
                    'merchendise': merchandise_item
                }
            } else if ($(this).parents('#radar-overview').length) {
                radar_label = $(this).attr('data-sr-label');
                radar_licence = $(this).attr('data-sr-licence');
                radar_item = {
                    'price': price,
                    'label' : radar_label,
                    'licence': radar_licence
                }
                radar = {
                    'radar': radar_item
                }
            } else {

                basketObj = {
                    'price': price,
                    'is_kb_member': is_kb_member,
                    'label': label,
                    'promotion': parseFloat(promotion),
                    'total': total
                }
            }
        });
        $.extend(true, basketObj, lk_attendence, merchandise, radar);

        $("#sr_creg_pricing_json").val(JSON.stringify(basketObj));
    }

    insertBasketJsonInField(basketObj) {
        $("#sr_creg_pricing_json").val(JSON.stringify(basketObj));
    }

    async refreshBasketJsonBasedOnFormular(data = false) {
        let oldShoppingBasket = '';
        const form = this.citySignupForm;
        if (!data){
            data = this.citySignupForm.serialize_all();
        } else {
            oldShoppingBasket = JSON.parse(data.sr_creg_pricing_json);
        }

        let newBasketObj = {};
        // KB-Membership
        newBasketObj.is_kb_member = false;
        if (data.sr_creg_is_kb_member) {
            newBasketObj.is_kb_member = true;
        }
        let priceArray = this.data.pricingJson_currentYear;
        await $.ajax({
            url:  form.data.apiUrl + "promotion/getPriceTables?sr_fstate=" + data.sr_creg_fstate + "&sr_api_key=" + form.data.apiKey,
            type: "GET",
            success: function (response) {
                priceArray = response;
            }
        });
        // Label of Main City
        const population = data.sr_creg_population;
        let price_population = '';
        let price_label = '';
        $.each(priceArray['pricing'], function (index, value) {
            if (population >= value[1]) {
                if (newBasketObj.is_kb_member === true) {
                    price_population = value[2];
                    price_label = " >= " + value[1] + ' ' + form.translationData.population + "<br> " + form.translationData.kbMember;
                } else {
                    price_population = value[3];
                    price_label = " >= " + value[1] + ' ' +  form.translationData.population;
                }
            }
        });
        newBasketObj.label = price_label;

        // attendance price
        newBasketObj.price = price_population;

        // total attendanceFee
        await $.ajax({
            url:  form.data.formUrl + "updateShoppingBasket&sr_returnmode=" + form.data.returnMode + "&XDEBUG_SESSION=IDEA",
            type: "POST",
            data: JSON.stringify( data ),
            success: function (response) {
                const result = JSON.parse(response);
                if (oldShoppingBasket) {
                    newBasketObj.promotion = oldShoppingBasket.promotion;
                } else {
                    newBasketObj.promotion = result.calculatedPromotion;
                }
                newBasketObj.total = result.totalAttendenceFee;
            }
        });

        let counter = 1;
        let lk_attendence = {}
        let item = {};
        for (let subCity in data['sub_cities']) {

            let is_kb_member_lk = false;
            let price = priceArray['lk_pricing'][3];
            if (data['sub_cities'][subCity]['sr_creg_is_kb_member']) {
                is_kb_member_lk = true;
                price = priceArray['lk_pricing'][2];
            }

            let label = counter + ". " + this.translationData.subCity;
            if (is_kb_member_lk) {
                label = counter + ". " + this.translationData.subCity + "<br>" + this.translationData.kbMember
            }

            item[counter] = {
                'price': price,
                'is_kb_member': is_kb_member_lk,
                'label': label,
                'cityName': data['sub_cities'][subCity]['sr_creg_cityname'],
                'counter': counter
            }

            lk_attendence = {
                'lk_attendence': item
            }

            counter += 1;
        }

        // Radar
        let licence_radar;
        let licence_radar_label = '';
        let radar_label = '';
        let radar_price = '';
        let radar = {};
        if (data.sr_creg_radar_enabled) {
            if (data.sr_creg_radar_duration === "1") {
                licence_radar = 0;
                licence_radar_label = form.translationData.radarFreeLicence;
                $.each(this.data.pricingRadar.one_year_licence, function(item, value) {
                    if (data.sr_creg_population >= value[1]) {
                        if (newBasketObj.is_kb_member === true) {
                            radar_label = ">= " + value[1] + ' ' + form.translationData.population + "<br>" + form.translationData.kbMember
                            radar_price = 0;
                        } else {
                            radar_label = ">= " + value[1] + ' ' + form.translationData.population;
                            radar_price = 0;
                        }
                    }
                });
            } else if (data.sr_creg_radar_duration === "1full") {
                licence_radar = 1;
                licence_radar_label = form.translationData.radarOneYearLicence;
                $.each(this.data.pricingRadar.one_year_licence, function(item, value) {

                    if (data.sr_creg_population >= value[1]) {
                        if (newBasketObj.is_kb_member === true) {
                            radar_price = value[2];
                            radar_label = ">= " + value[1] + ' ' + form.translationData.population + "<br>" + form.translationData.kbMember;
                        } else {
                            radar_price = value[3];
                            radar_label = ">= " + value[1] + ' ' + form.translationData.population;
                        }
                    }
                });
            } else if (data.sr_creg_radar_duration === "3") {
                licence_radar = 3;
                licence_radar_label = form.translationData.radarThreeYearLicence;
                $.each(this.data.pricingRadar.three_year_licence, function(item, value) {

                    if (data.sr_creg_population >= value[1]) {
                        if (newBasketObj.is_kb_member === true) {
                            radar_price = value[2];
                            radar_label = ">= " + value[1] + ' ' + form.translationData.population + "<br>" + form.translationData.kbMember;
                        } else {
                            radar_price = value[3];
                            radar_label = ">= " + value[1] + ' ' + form.translationData.population;
                        }
                    }
                });
            }
            radar = {
                'radar': {
                    label: radar_label + "<br>" + licence_radar_label,
                    licence: licence_radar,
                    price: radar_price
                }
            }
        }

        // Merchandise
        const merchValue_1 = $('#sr_creg_merchandise_1');
        const merchValue_3 = $('#sr_creg_merchandise_3');
        const merchValue_7 = $('#sr_creg_merchandise_7');
        const merchValue_4 = $('#sr_creg_merchandise_4');
        const merchValue_6 = $('#sr_creg_merchandise_6');
        const merchValue_8 = $('#sr_creg_merchandise_8');
        let merchObj = {};
        if (data.sr_creg_merchandise_1 > 0) {
            merchObj[merchValue_1.attr('data-label')] = {
                price: merchValue_1.attr('data-price') * data.sr_creg_merchandise_1,
                amount: data.sr_creg_merchandise_1,
                label: merchValue_1.attr('data-label')
            }
        }

        if (data.sr_creg_merchandise_3 > 0) {
            merchObj[merchValue_3.attr('data-label')] = {
                price: merchValue_3.attr('data-price') * data.sr_creg_merchandise_3,
                amount: data.sr_creg_merchandise_3,
                label: merchValue_3.attr('data-label')
            }
        }

        if (data.sr_creg_merchandise_7 > 0) {
            merchObj[merchValue_7.attr('data-label')] = {
                price: merchValue_7.attr('data-price') * data.sr_creg_merchandise_7,
                amount: data.sr_creg_merchandise_7,
                label: merchValue_7.attr('data-label')
            }
        }

        if (data.sr_creg_merchandise_4 > 0) {
            merchObj[merchValue_4.attr('data-label')] = {
                price: merchValue_4.attr('data-price') * data.sr_creg_merchandise_4,
                amount: data.sr_creg_merchandise_4,
                label: merchValue_4.attr('data-label')
            }
        }

        if (data.sr_creg_merchandise_6 > 0) {
            merchObj[merchValue_6.attr('data-label')] = {
                price: merchValue_6.attr('data-price') * data.sr_creg_merchandise_6,
                amount: data.sr_creg_merchandise_6,
                label: merchValue_6.attr('data-label')
            }
        }

        if (data.sr_creg_merchandise_8 > 0) {
            merchObj[merchValue_8.attr('data-label')] = {
                price: merchValue_8.attr('data-price') * data.sr_creg_merchandise_8,
                amount: data.sr_creg_merchandise_8,
                label: merchValue_8.attr('data-label')
            }
        }

        const merchandise = {
            'merchandise': merchObj
        }

        return $.extend(true, newBasketObj, lk_attendence, radar, merchandise);

    }

    recreateShoppingBasketBasedOnBasketJson() {
        const basketJson = JSON.parse($('#sr_creg_pricing_json').val());
        const fstate = $('#sr_creg_fstate').val();
        const population = $('#sr_creg_population').val();

        // clear shopping basket
        const attendanceWrapper = document.querySelector('#attendence-overview tbody');
        if (attendanceWrapper) {
            attendanceWrapper.remove();
        }

        const lkAttendanceWrapper = document.querySelectorAll('#attendence-overview .sub-commune');
        lkAttendanceWrapper.forEach(item => {
            if (item) {
                item.remove();
            }
        });

        const radarFee = document.querySelector('#radar-overview tbody');
        if (radarFee) {
            radarFee.remove();
        }

        const merchandiseFee = document.querySelector('#merchandise-overview tbody');
        if (merchandiseFee) {
            merchandiseFee.remove();
        }

        // insert attendance fee & lk attendance fee
        const attendanceWrapperNew = document.querySelector('#attendence-overview');
        let html = "<tr class='attend-label' style='display: block;'><td>Teilnahmegebühr (inkl. 19% MwSt)</td><td></td></tr>";
        html += "<tr class='attendence'>";
        html += "<td class='price-label' data-sr-json='attendence'>" + basketJson.label + "</td>";
        html += "<td class='price-list' data-sr-label='" + basketJson.label + "' data-sr-json='"+ basketJson.price + "'>";
        html += srCurrencyNumberFormat((basketJson.price/100), '€') + "</td></tr>";

        if (basketJson.lk_attendence) {
            for (let item in basketJson.lk_attendence) {

                html += "<tr class='sub-commune' data-sr-offset='" + (basketJson.lk_attendence[item].counter * 100) + "' >";
                html += "<td class='price-label' data-sr-json='lk_attendence'>" + basketJson.lk_attendence[item].label + "</td>";
                html += "<td class='price-list' data-sr-counter='" + (basketJson.lk_attendence[item].counter) + "' data-sr-label='" + basketJson.lk_attendence[item].label + "' data-sr-json='" + basketJson.lk_attendence[item].price + "' data-sr-cityname='" + basketJson.lk_attendence[item].cityName +"'>";
                html += srCurrencyNumberFormat((basketJson.lk_attendence[item].price / 100), '€');
                html += "</td></tr>";
            }
        }
        attendanceWrapperNew.innerHTML = html;

        // insert promotion
        if (citySignUpConfig.g_update_basket_disabled === false) {
            this.ajaxCalculatePromotion(fstate, population);
        }

        // insert radar
        if (basketJson.radar) {
            const radarWrapper = document.querySelector('#radar-overview');
            html = "<tr class='radar-label' style='display: block;'><td>RADar!-Nutzung(inkl. 19% MwSt)</td><td></td></tr>";
            html += "<tr class='radar_fee'>";
            html += "<td class='price-label' data-sr-json='radar_fee'>" + basketJson.radar.label + "</td>";
            html += "<td class='price-list' data-sr-licence='" + basketJson.radar.licence + "' data-sr-label='" + basketJson.radar.label + "' data-sr-json='"+ basketJson.radar.price + "'>"
            html += srCurrencyNumberFormat((basketJson.radar.price/100), '€');
            html += "</td></tr>";

            radarWrapper.innerHTML = html;
        }

        // insert merchandise
        if (basketJson.merchandise) {
            const merchandiseWrapper = document.querySelector('#merchandise-overview');
            html = "<tr class='merch-label' style='display: block;'><td>Werbematerialien (inkl. 19% MwSt)</td><td></td></tr>";

            for (let item in basketJson.merchandise) {
                html += "<tr class='" + basketJson.merchandise[item].label + "'><td class='price-label' data-sr-json='" + basketJson.merchandise[item].label + "'>" + basketJson.merchandise[item].label + "</td>";
                html += "<td class='price-list' data-amount='" + basketJson.merchandise[item].amount  +"' data-sr-label='" + basketJson.merchandise[item].label + "' data-sr-json="+ basketJson.merchandise[item].price + ">"
                html += srCurrencyNumberFormat((basketJson.merchandise[item].price/100), '€');
                html += "</td></tr>";
            }

            merchandiseWrapper.innerHTML = html;
        }

        this.calculateTotal();
    }
}

window.CitySignupShoppingBasketCalculation = CitySignupShoppingBasketCalculation;
