/* js code for the redesign 2025 header navigation */
function initFadeIn() {
    /* fade in module on scroll */
    let elementsArray = document.querySelectorAll('.sr-module');
    if (elementsArray) {
        window.addEventListener('scroll', fadeIn);

        function fadeIn() {
            for (var i = 0; i < elementsArray.length; i++) {
                var elem = elementsArray[i]
                var distInView = elem.getBoundingClientRect().top - window.innerHeight + 120;
                if (distInView < 0) {
                    elem.classList.add('sr-opacity-100');
                    //elem.classList.add('sr-translate-y-0');
                }
            }
        }

        fadeIn();
    }
}

const observer = new ResizeObserver(entries => {
    const entry = entries[0];
    initFadeIn();
});

function initHeadeNavigation() {
    if (navigationInitialized) return;
    navigationInitialized = true;

    // menu background overlay
    const navItems = document.querySelectorAll('#main-menu .nav-item');
    var navOverlay = document.getElementById('overlay');
    var navOverlayState = false;

    function overlayOn() {
        navOverlay.style.opacity = '1';
        navOverlay.style.zIndex = '2001';
        navOverlayState = true;
    }

    function overlayOff() {
        navOverlay.style.opacity = '0';
        navOverlay.style.zIndex = '-1';
        navOverlayState = false;
    }

    if (window.innerWidth >= 1280) {
        navItems.forEach(item => {
            item.addEventListener('mouseover', overlayOn);
            item.addEventListener('mouseout', overlayOff);
        });
    }

    // burger menu toggle
    const burgerButton = document.getElementById('burger');
    const mainMenu = document.getElementById('main-menu');

    if (burgerButton && mainMenu) {
        burgerButton.addEventListener('click', function () {
            if (navOverlayState === false) {
                overlayOn();
            } else {
                overlayOff();
            }
            // navOverlayState ? overlayOn() : overlayOff();
            const burgerIcon = burgerButton.querySelector('i');
            if (burgerIcon && burgerIcon.classList.contains('icon')) {
                burgerIcon.classList.toggle('icon-menu');
                burgerIcon.classList.toggle('icon-close');
            }
            mainMenu.classList.toggle('sr-hidden');
        });
    }

    /* main menu burger menu */
    let findLoginAttempts = 0;
    const maxAttempts = 50;
    let loginMenu; 

    const loginMenuClickHandler = function () {
      let navLogin = loginMenu.closest('.nav-login');
      if (navLogin) {
        navLogin.classList.toggle('open');
      } 
    };

    // close navLogin when hovering other menu items
    const otherNavLinks = document.querySelectorAll('.navbar-nav .nav-link');
    otherNavLinks.forEach(link => {
      link.addEventListener('mouseover', function () {
      let navLogin = document.querySelector('.nav-login.open');
      if (navLogin) {
        navLogin.classList.remove('open');
      }
      });
    });

    // close navLogin when clicking outside of menu
    document.addEventListener('click', function (event) {
      let navLogin = document.querySelector('.nav-login.open');
      if (navLogin && !navLogin.contains(event.target) && !document.getElementById('main-menu').contains(event.target)) {
      navLogin.classList.remove('open');
      }
    });

    function checkLoginMenu() {
      loginMenu = document.querySelector('#login-menu');

      if (loginMenu) {

        if (window.innerWidth < 1280) {
          document.querySelectorAll('#main-menu .sr-group > a').forEach(link => {
            link.addEventListener('click', function (e) {
            e.preventDefault();
            this.classList.toggle('sr-bg-sr-blue-20');
            if (this.nextElementSibling.classList.contains('sr-megamenu')) {
              this.nextElementSibling.classList.toggle('sr-hidden');
            }
            });
          });

        } else {
          if (!loginMenu.hasAttribute('data-listener')) {
            loginMenu.addEventListener('click', loginMenuClickHandler);
            loginMenu.dataset.listener = "true";
          }
        }

      } else if (findLoginAttempts < maxAttempts) {
        findLoginAttempts++;
        setTimeout(checkLoginMenu, 200);
      } else {
        console.log('No #login-menu found');
      }
    }
    checkLoginMenu();

    // Handle resize event efficiently
    let resizeTimeout;
    window.addEventListener('resize', function() {
      clearTimeout(resizeTimeout);
      resizeTimeout = setTimeout(checkLoginMenu, 200);
    });


    /* meta menu / langmenu */
    function initLangMenu(attempts = 0) {
      const links = document.querySelectorAll('.menumeta .sr-group > a');
      if (links.length > 0) {
        links.forEach(link => {
          link.addEventListener('click', function (e) {
            e.preventDefault();
            this.nextElementSibling.classList.toggle('sr-hidden');
          });
        });
      } else if (attempts < 10) {
        setTimeout(() => initLangMenu(attempts + 1), 200);
      } else {
        console.log('No language menu found');
      }
    }
    initLangMenu();
    

    initFadeIn();
    observer.observe(document.querySelector('body'));

    // top nav scroll collapse
    let oldScroll = 0;
    const topNav = document.querySelector('#topnav');
    let height = '34px';

    window.addEventListener('scroll', function () {
        if (window.innerWidth >= 1280) {
            if (oldScroll > window.scrollY && topNav) {
                // scrolling up
                height = '34px';
            } else {
                // scrolling down
                height = '0';
            }
            if (topNav) {
                topNav.style.height = height;
            }
            oldScroll = window.scrollY;
        } else {
            if (topNav) {
                topNav.style.height = '34px';
            }
        }
    });

}

// Wait for DOMContent & dynamicLoader
let evalQueueDone = false;
let navigationInitialized = false; 

document.addEventListener('dynamicLoader.evalQueueDone', function () {
  evalQueueDone = true;
  initHeadeNavigation();
});

setTimeout(function () {
  if (!evalQueueDone) {
    initHeadeNavigation();
  }
}, 5000);

document.addEventListener('DOMContentLoaded', function () {
  if (!navigationInitialized) {
    initHeadeNavigation();
  }
});
