class QuillEditor {
    constructor() {
    }

    createEditor(elementId, useCounter = false, countAmount = 0, preValue='') {
        const toolbarOptions = [
            ['bold', 'italic', 'underline', 'strike'],
            [{ 'list': 'ordered'}, { 'list': 'bullet' }],
            [{ 'script': 'sub'}, { 'script': 'super' }],
            [{ 'indent': '-1'}, { 'indent': '+1' }],
            ['link']
        ];

        const wrapper = document.querySelector('#' + elementId);

        wrapper.innerHTML = '<div class="quillEditor">' + preValue + '</div>';

        if (useCounter) {
            wrapper.innerHTML += '<small class="d-block pt-xs-10" id="' + elementId +'-character-count">Noch ' + countAmount + ' Zeichen</small>';
        }

        const quill = new Quill('#' + elementId + ' .quillEditor', {
            theme: 'snow',
            modules: {
                toolbar: toolbarOptions,
            }
        });
        const element = document.querySelector('#' + elementId + ' .quillEditor');
        element.querySelector('.ql-editor').style.height = '200px';

        quill.on('text-change', (delta, oldDelta, source) => {
            const element = document.querySelector('#' + elementId + ' .quillEditor');
            element.querySelector('.ql-editor').style.height = '200px';

            if (useCounter) {
                let counterText = document.querySelector('#' + elementId +'-character-count');
                let restCounter = countAmount - quill.getText().length + 1;
                counterText.innerHTML = 'Noch ' + restCounter + ' Zeichen';

                if (restCounter < 0) {
                    quill.setContents(oldDelta);
                }
            }
        });

        const linkButton = document.querySelector('#' + elementId + ' .ql-link');
        linkButton.addEventListener('click', () => {
            const linkSaveButton = document.querySelector('.ql-tooltip.ql-editing .ql-action');
            const linkInputField =  document.querySelector('.ql-tooltip.ql-editing input');

            linkSaveButton.addEventListener('click', () => {
                const contentText = document.querySelector('#' + elementId + ' .ql-editor');
                contentText.querySelectorAll('a').forEach((item) => {
                    const linkHrf = item.getAttribute('href');
                    if (!linkHrf.includes('http://') && !linkHrf.includes('https://')) {
                        item.setAttribute('href', 'https://' + linkHrf);
                    }
                });
            });

            linkInputField.addEventListener('keydown', (event) => {
                if (event.key === 'Enter') {
                    const contentText = document.querySelector('#' + elementId + ' .ql-editor');
                    contentText.querySelectorAll('a').forEach((item) => {
                        const linkHrf = item.getAttribute('href');
                        if (!linkHrf.includes('http://') && !linkHrf.includes('https://')) {
                            item.setAttribute('href', 'https://' + linkHrf);
                        }
                    });
                }
            })
        });
        return quill;
    }

    getEditor(element) {
        const quillElement = element.querySelector('.quillEditor');
        return Quill.find(quillElement);
    }
}

window.QuillEditor = QuillEditor;
