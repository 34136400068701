/**
 * Check browser
 */

$(function() {

    // @see:  https://stackoverflow.com/questions/19999388/check-if-user-is-using-ie
    var ua           = window.navigator.userAgent;
    var msie         = ua.indexOf("MSIE ");
    var redirectURL  = "https://www.stadtradeln.de/browserhinweis";
    var redirectHash = "#ie11";     // The hash is used to break the redirect loop

    if (msie > 0 || navigator.userAgent.match(/Trident.*rv\:11\./))  // If Internet Explorer
    {
        // Check if we need to break the redirect loop
        if (window.location.hash !== redirectHash) {
            // Redirect to Typo3 page
            window.location.href = redirectURL + redirectHash;
        }
    }

});


/**
 * Navbar
 */

/* Navbar active links */
$(function() {

  function getPageName(url) {
    url = url.split("/");
    url = url[url.length - 1];
    url = url.split("#");
    url = url[0];
    return url;
  }

  var currentUrl = window.location.href;
  var pageName = getPageName(currentUrl);

  $(".navbar-nav li > a[href$='" + pageName + "']").parent("li").first()
    .addClass("active");

  $(".navbar-nav > li").has(".active").first()
    .addClass("active");

});


/* Dropdown submenu positioning (left or right) */

$(function() {
  $(".main-nav ul.dropdown-menu a[data-toggle=dropdown]").hover(function() {
    var menu = $(this).parent().find("ul"),
        menupos = menu.offset();

    if ((menupos.left + menu.width()) + 30 > $(window).width()) {
      $(this).parent().addClass('pull-left');
    }

    return false;
  });
});


/* Toggle dropdown menus on hover instead of on click */

//  $(".nav .dropdown").hover(function() {
//   $(this).find(".dropdown-toggle").dropdown("toggle");
// });

// Where 991 is the max width of small screens (tablets)
$(function(){
    // normal navigation, open when hovering
    $('.main-nav .desktopmenu').hover(
        function() {
            if ($(window).width() > 991) $(this).addClass('open');
        },
        function() {
            if ($(window).width() > 991) $(this).removeClass('open');
        }
    );

    //delayed function to wait for orkspinner to load in

    // Eventlistener on SR-Typo3 site for login dropdown workaround
    document.addEventListener('ork.evalQueueDone', function(e){
        loginDropdownHelper();
    });

});

// login dropdown workaround for radelkalender
setTimeout(function() {
    loginDropdownHelper();
}, 500);


$(function(){
    // Animate progressbar
    $.each( $(".bar_fg"), function() {
        let setwidth = $(this).data("progress") + "%";
        $(this).animate({
            width: setwidth
        }, 1500 );
    });
});

$(function(){
    $(document).on('click', '#js-offcanvas-close', function(event) {
      event.preventDefault();
      $("#js-bootstrap-offcanvas").trigger("offcanvas.close");
    });

    $('.dropdown input').click(function(event) {
        event.stopPropagation();
    });
});

$(function(){
    // Convert list with links to mobile dropdown
    $("<select />").appendTo(".generate-dropdown");
    $("<option />", {
       "selected": "selected",
       "value"   : "",
       "text"    : "Springe zum Inhalt"
    }).appendTo(".generate-dropdown select");
    $(".generate-dropdown a").each(function() {
      var el = $(this);
      $("<option />", {
        "value"   : el.attr("href"),
        "text"    : el.text()
     }).appendTo(".generate-dropdown select");
    });
    $(".generate-dropdown select").change(function() {
      window.location = $(this).find("option:selected").val();
    });
});

$(document).ready(function() {

    // #2607
    window.srInitEncml = function() {
        $('span.sr-encml').each(function () {
            var addr     = $(this).html();
            var htmlAddr = addr.replace("<i>sr-encml</i>", "(a<span class='visibility:hidden'><!-- enc --></span>t)");
            var jsAddr   = btoa(addr);
            $(this).after('<a class="sr-encml-l" href="#" data-enc="'+jsAddr+'">'+htmlAddr+'<\/a>');
            $(this).remove();
        });
        $('a.sr-encml-l').off('click').click(function () {
            var text = $(this).attr("data-enc");
            text = atob(text).toString().replace(" <i>sr-encml</i> ", atob("QA==").toString());
            var p = atob("TWFJTA==").toString().toLowerCase()+atob("dE86").toString().toLowerCase();
            window.location = p+text;
            return false;
        });
    }


    // Fix emails
    window.srInitMailMe = function() {
        $('span.mailme').each(function () {
            var at = / (at) /;
            var dot = / (dot) /g;
            var addr = $(this).text().replace(at,"@").replace(dot,".");
            $(this).after('<a href="mailto:'+ addr +'">' + addr +'<\/a>');
            $(this).remove();
        });
    }


    window.srInitEncml();
    window.srInitMailMe();

    // #3176 javascript part for m-18-katalog lightbox
    /*
    $('.image-wrapper').click(function () {
        $(this).children('.lightbox-background, .lightbox-wrapper').addClass('active');
    });

    $('.icon-close').click(function (event) {
        event.stopPropagation();
        $('.icon-close').parents('.lightbox-wrapper').removeClass('active');
        $('.icon-close').parents('.lightbox-wrapper').prev().removeClass('active');
    }); */

    // #4115 Close tooltips on mobile
    $('html').on('click', function(e) {
        if (typeof $(e.target).data('original-title') == 'undefined' &&
           !$(e.target).parents().is('.popover.in')) {
          $('[data-original-title]').popover('hide');
        }
    });

    //make select2 also work with dark-bg
    $('.dark-bg main select, main .dark-bg select, .dark-bg-popover main select, main .dark-bg select').on('select2:open', function (e) {
        $('html').find('body > span.select2-container--open').addClass('dark-bg');
    });

    //make popovers also work with dark-bg
    $(".dark-bg [data-toggle='popover'], .dark-bg-popover [data-toggle='popover']").popover('destroy');
    setTimeout(function () {
        $(".dark-bg [data-toggle='popover'], .dark-bg-popover [data-toggle='popover']").popover({
            html: true,
            template: '<div class="popover dark-bg"><div class="arrow"></div><h3 class="popover-title"></h3><div class="popover-content"></div></div>'
        });
    }, 200);

});

// That's a really ugly workaround for https://github.com/iamphill/Bootstrap-Offcanvas/issues/57
$(function() {
    $(window).on('resize',function(ev){
        var focused = $(':focus');
        if (focused.attr('name') === 'sr_username' || focused.attr('name') === 'sr_password') {
            $('.navbar-offcanvas').addClass('in');
        }
    });
});


// global crossdomain and credential handling for ajax calls
$.ajaxSetup({
    crossDomain: true,
    xhrFields: {
        withCredentials: true
    }
})

// keep login dropdown open when hovering auto complete options, only close dropdown on click (outside) or hovering other options
function loginDropdownHelper() {

    var menulogin = $('.login-menu').parent();
    $(menulogin).hover(function() {
        if ($(window).width() > 991) $(this).addClass('open');
    });
    $('.main-nav .desktopmenu').hover(function() {
        if ($(window).width() > 991) $(menulogin).removeClass('open');
    });

    // normal dropdown behaviour when logged in on mein-sr menu
    var menuloggedin = $('.logged-in-menu').parent();
    $(menuloggedin).hover(function() {
            if ($(window).width() > 991) $(this).addClass('open');
        },
        function() {
            if ($(window).width() > 991) $(this).removeClass('open');
        });
}
