class CitySignupAddSubCity {
    constructor(data, translationData) {
        this.data = data;
        this.translationData = translationData;
    }

    getNewSubCityHtml(tabOffset = 0) {

        let html = this.data.subHtml.html;
        if (tabOffset === 0) {
            tabOffset = (this.getCurrentOffset() + 1) * 100;
        }

        return html.replace(/{TAB_OFFSET}/g, tabOffset.toString());
    }


    subCityAccordeonWrapperTemplate(titel, tabOffset) {

        let html = '<div id="akkordeon-wrapper_' + tabOffset + '" class="akkordeon-topics" data-sr-offset="' + tabOffset + '">';
        html += '<div class="panel akkordeon-topic">';
        html += '<div class="akkordeon-topic-header">';
        html += '<a href="#openFormSubCity_' + tabOffset + '" class="collapsed" role="button" data-parent="#akkordeon-wrapper_' + tabOffset + '" data-toggle="collapse" aria-expanded="false">';
        html += '<div class="akkordeon-topic-link"><span class="missing-field-info hidden">!</span>' + titel + '</div>';
        html += '</a></div>';
        html += '<div class="akkordeon-topic-body collapse" id="openFormSubCity_' + tabOffset + '" aria-expanded="false" style="height: 0px;">';
        html += '<div class="akkordeon-content">' + this.getNewSubCityHtml(tabOffset) + '</div>';
        html += '</div></div></div>';

        return html;
    }

    collectDataFromSubCityForm(citySignUpForm, tabOffset) {
        return citySignUpForm.serialize_sub($('#sr_kommunenregistrierung_sub_' + tabOffset));
    }

    fillSubCityFormWithData(citySignupForm, tabOffset, data, preLoad, agsData = false) {
        for (const key in data) {
            if (key !== "extra_coordination" && key !== "sr_creg_citytype") {
                let ret = $("#sr_kommunenregistrierung_sub_" + tabOffset + " [name=" + key + "]:not([type=checkbox]):not([type=radio])").val(data[key]);
                if (ret.length === 0) ret = $("#sr_kommunenregistrierung_sub_" + tabOffset + " [name=" + key + "]:checkbox").attr("checked", data[key]);  // FIXME: Checks if set and 0
                if (ret.length === 0) ret = $("#sr_kommunenregistrierung_sub_" + tabOffset + " [name=" + key + "][type=radio][value='" + data[key] + "']").attr("checked", "checked");

            } else if (key === "sr_creg_citytype") {
                let retSelector = $("#sr_kommunenregistrierung_sub_" + tabOffset + " [name=" + key + "]:not([type=checkbox]):not([type=radio])");
                retSelector.val(data[key]);
                retSelector.change();

            } else if (key === "extra_coordination") {
                let extra_count = data.extra_coordination.length;

                for (let j=0; j<extra_count; j++)
                {
                    this.add_sub_extra_coordination(citySignupForm, tabOffset);
                    for (let extra_key in data.extra_coordination[j]) {
                        let ret = $("#sr_kommunenregistrierung_sub_extra_coordination_" + tabOffset + " .sr_kommunenregistrierung_sub_extra:last [name=" + extra_key + "]:not([type=checkbox]):not([type=radio])").val(data.extra_coordination[j][extra_key]);
                        if (ret.length === 0) ret = $("#sr_kommunenregistrierung_sub_extra_coordination_" + tabOffset + " .sr_kommunenregistrierung_sub_extra:last [name=" + extra_key + "]:checkbox").attr("checked", data.extra_coordination[j][extra_key]);  // FIXME: Checks if set and 0
                        if (ret.length === 0) ret = $("#sr_kommunenregistrierung_sub_extra_coordination_" + tabOffset + " .sr_kommunenregistrierung_sub_extra:last [name=" + extra_key + "][type=radio][value='" + data.extra_coordination[j][extra_key] + "']").attr("checked", "checked");
                    }
                }
            }
        }

        if (!preLoad) {
            citySignupForm.checkPreYearMembershipWithCurrentMembership(data['sr_creg_ags'], "sr_kommunenregistrierung_sub_" + tabOffset);
        } else {
            citySignupForm.checkPreYearMembershipWithCurrentMembership(data['sr_creg_ags'], "sr_kommunenregistrierung_sub_" + tabOffset, agsData[data['sr_creg_ags']]);
        }
    }

    /** geprüft */
    add_sub_extra_coordination(citySignupForm, identifier)
    {
        const html = citySignupForm.subExtraHtml.html;
        $("#sr_kommunenregistrierung_sub_extra_coordination_"+identifier).append(html);
        $("#sr_creg_country").change(); // Update country-specific fields
        citySignupForm.citySignupValidation.validation_register();

        $('.select2salutation').select2();

        $('.select2title').select2();

        $(".removeSubExtraCoordination").off('click').on('click', (e) => {
            this.remove_sub_extra_coordination(e.currentTarget, citySignupForm);
        });
    }

    /** geprüft */
    initializeNewSubCity(citySignupForm, tabOffset, citySignupShoppingBasketInit = false, preLoad = false) {
        const citySignupValidation = new CitySignupValidation(this.translationData);
        const citySignupInitialization = new CitySignupInitialization(this.translationData);

        $('[data-toggle="popover"]').popover();
        citySignupForm.update_map(tabOffset);

        if ($("#sr_creg_radar_enabled").is(":checked")) {
            $("#sr_creg_radar_enabled_" + tabOffset.toString()).attr("readonly", "readonly");
            $("#sr_creg_radar_enabled_label_" + tabOffset.toString()).css("opacity", 0.5);
        }

        citySignupForm.update_member_checkboxes();

        $('.sr_creg_sub_coordination_box_' + tabOffset + 1).hide();

        if (!preLoad) {
            $("#sr_creg_country").change(); // Update country-specific fields
            let lastOffsetEntry = document.querySelector('.akkordeon-topics:last-child').getAttribute('data-sr-offset');
            lastOffsetEntry = lastOffsetEntry / 100 + 1;
            for (let i = 1; i <= lastOffsetEntry; i++) {
                this.initializeSubCitySelect2(i * 100);
            }

            if (citySignupShoppingBasketInit !== false) {

                if (citySignUpConfig.g_update_basket_disabled === false && this.data.pricingJson_currentYear !== false) {
                    citySignupShoppingBasketInit.initTrigger()
                    citySignupShoppingBasketInit.citySignupShoppingBasketUpdate.update_basket_new($('#sr_kommunenregistrierung_sub_' + (tabOffset - 100)),'lk_attendence');
                }
            }
        }

        this.initializeSubCityPopover(tabOffset);
        this.initializeSubCityRadar(tabOffset, citySignupForm, citySignupValidation);
        this.initializeSubCityEventListener(tabOffset, citySignupForm);
        citySignupInitialization.initializeEventListenerAgs(citySignupForm, this.data.registerMode, this.data.adminMode, this.data.apiUrl, this.data.apiKey, tabOffset);

        let lookupCoordinates = $('.lookupCoordinates[data-sr-offset]');
        lookupCoordinates.off('click').on('click', (e) => {
           citySignupForm.lookup_coordinates(e.currentTarget.dataset.srOffset);
        });

        // reinitialize event listener for sub city if the accordeon will open
        $('#subcity-existing-content-wrapper .akkordeon-topics .akkordeon-topic-header').off('click').on('click', (event) => {
            tabOffset = $(event.currentTarget).parents('.akkordeon-topics').attr('data-sr-offset');
            this.initializeSubCityPopover(tabOffset);
            this.initializeSubCityRadar(tabOffset, citySignupForm, citySignupValidation);
            this.initializeSubCityEventListener(tabOffset, citySignupForm);
            citySignupInitialization.initializeEventListenerAgs(citySignupForm, this.data.registerMode, this.data.adminMode, this.data.apiUrl, this.data.apiKey, tabOffset);
            citySignupInitialization.initializeRadarDatepicker(this.data.radarMinDate, this.data.radarMaxDate, citySignupForm, citySignupValidation, tabOffset);

            // show mandatory fields and create add event listener to update error msg when changing an field
            citySignupForm.citySignupValidation.getMissingMandatoryFieldItems(document.querySelector('#sr_kommunenregistrierung_sub_' + tabOffset), true, false);
            citySignupForm.citySignupValidation.implementEventListenerForCheckingMandatoryFields();
        });

    }

    /** geprüft */
    getCurrentOffset() {
        return $('.sr_kommunenregistrierung_sub').length;
    }

    update_sub_coordination(tabOffset, citySignupForm) {
        if ($("#sr_creg_sub_coordination_" + tabOffset).is(":checked")) {
            $('.sr_creg_sub_coordination_box_' + tabOffset).show();
            $("[data-sr-role=sub_coordination_" + tabOffset + "]").addClass("sr_validate_nonempty").removeAttr("readonly");
            $("#sr_creg_coordination_function_" + tabOffset).removeClass("sr_validate_nonempty");
            $("#sr_creg_coordination_username_" + tabOffset).removeAttr("readonly");
            $("#sr_creg_coordination_is_radar_admin_" + tabOffset).removeAttr("readonly");
            $(".sr_creg_sub_coordination_box_" + tabOffset).removeClass("sr_creg_half_fade");
        } else {
            $('.sr_creg_sub_coordination_box_' + tabOffset).hide();
            $("[data-sr-role=sub_coordination_" + tabOffset + "]").removeClass("sr_validate_nonempty").attr("readonly", "readonly").val("").nextAll(".sr_form_feedback").html("");
            $("#sr_creg_coordination_username_" + tabOffset).attr("readonly", "readonly").val("");
            $("#sr_creg_coordination_is_radar_admin_" + tabOffset).attr("readonly", "readonly").removeAttr("checked");
            $("#sr_creg_coordination_title_" + tabOffset).val("");
            $(".sr_creg_sub_coordination_box_" + tabOffset).addClass("sr_creg_half_fade");
        }

        citySignupForm.citySignupValidation.validation_register();
    }

    /** geprüft */
    initializeSubCitySelect2(tabOffset) {
        const form = this;

        let kbMember = $('#sr_creg_is_kb_member_' + tabOffset);
        let agfkMember = $('#sr_creg_is_agfk_member_' + tabOffset);
        let citySignupInitialization = new CitySignupInitialization(this.translationData);

        citySignupInitialization.initializeAgsSelectField($('.select2-kommune'), this.data.apiUrl, this.data.apiKey);

        if (tabOffset) {
            $('#sr_creg_ags_search_main_' + tabOffset).off('select2:select').on('select2:select', function(e) {
                $('#sr_creg_cityname_' + tabOffset).val(e.params.data.city);
                $('#sr_creg_uuid_' + tabOffset).val(e.params.data.id);
                $('#sr_creg_ags_' + tabOffset).val(e.params.data.ags).change();
                citySignupInitialization.callAgsInfo(e.params.data.ags, form.data.registerMode, form.data.adminMode,kbMember, agfkMember, form.data.apiUrl, form.data.apiKey, tabOffset)
            });
        }


        $('.select2-bezeichnung').select2({});
        $('.select2salutation').select2({});
        $('.select2title').select2({});
    }

    /** geprüft */
    initializeSubCityPopover(tabOffset) {
        // Popover - Tooltips!
        $('[data-toggle="popover"]').popover();

        $("#sr_creg_cityname_" + tabOffset).on({
            'focus': function() {
                $("#sr_creg_cityname_hover_" + tabOffset).popover('show');
            },
            'focusout': function() {
                $("#sr_creg_cityname_hover_" + tabOffset).popover('hide');
            },
        });

        $("#sr_creg_ags_" + tabOffset).on({
            'focus': function() {
                $("#sr_creg_ags_hover_" + tabOffset).popover('show');
            },
            'focusout': function() {
                $("#sr_creg_ags_hover_" + tabOffset).popover('hide');
            },
        });

        $("#sr_creg_population_" + tabOffset).on({
            'focus': function() {
                $("#sr_creg_population_hover_" + tabOffset).popover('show');
            },
            'focusout': function() {
                $("#sr_creg_population_hover_" + tabOffset).popover('hide');
            },
        });

        $("#sr_creg_pl_num_" + tabOffset).on({
            'focus': function() {
                $("#sr_creg_pl_num_hover_" + tabOffset).popover('show');
            },
            'focusout': function() {
                $("#sr_creg_pl_num_hover_" + tabOffset).popover('hide');
            },
        });
    }

    /** geprüft */
    initializeSubCityRadar(tabOffset, citySignupForm, citySignupValidation) {

        let radarSection = $("#sr_creg_radar_section_" + tabOffset);
        let radarEnabled = $("#sr_creg_radar_enabled_" + tabOffset);
        let radarContact = $("#sr_creg_radar_contact_" + tabOffset);
        let radarContactSection = $("#sr_creg_radar_contact_section_" + tabOffset);
        let radarDateFromCampaign = $("#sr_creg_radar_date_from_campaign_" + tabOffset);
        let radarEndDate = $("#sr_creg_radar_end_date_" + tabOffset);
        let radarStartDate = $("#sr_creg_radar_start_date_" + tabOffset);

        if ($("#sr_creg_radar_enabled").is(":checked")) {
            $("#sr_creg_radar_enabled_" + tabOffset.toString()).attr("readonly", "readonly");
            $("#sr_creg_radar_enabled_" + tabOffset.toString()).attr("disabled", "disabled");
            $("#sr_creg_radar_enabled_label_" + tabOffset.toString()).css("opacity", 0.5);
        }

        radarStartDate.datepicker("destroy").datepicker({
            changeMonth: true,
            changeYear: true,
            format: "dd.mm.yyyy",
            daysOfWeekHighlighted: "6,0",
            language:   "de",
            calenderWeeks: true,
        })

        radarEndDate.datepicker("destroy").datepicker({
            changeMonth: true,
            changeYear: true,
            format: "dd.mm.yyyy",
            daysOfWeekHighlighted: "6,0",
            language:   "de",
            calenderWeeks: true,
        })

        if (!radarEnabled.is(":checked")) {
            radarSection.hide();
        }
        radarEnabled.off('click').on('click', function() {
            let radarEnabledMainSelector = $("#sr_creg_radar_enabled");
            let radarPreviousActive = $("#sr_creg_radar_previous_active_" + tabOffset) ;
            let radarDuration = $("#sr_creg_radar_duration_1_" + tabOffset);
            let radarEndDate = $("#sr_creg_radar_end_date_" + tabOffset);
            let radarStartDate = $("#sr_creg_radar_start_date_" + tabOffset);
            let radarAdmin = $("#sr_creg_radar_is_radar_admin_" + tabOffset);
            let ags = $('#sr_creg_ags_' + tabOffset).val();
            const ivmSwitcher =  $("[data-sr-name=sr_ivm_switcher_" + tabOffset +"]");
            radarSection = $("#sr_creg_radar_section_" + tabOffset);
            radarContact = $("#sr_creg_radar_contact_" + tabOffset);
            radarContactSection = $("#sr_creg_radar_contact_section_" + tabOffset);

            if ($(this).is(":checked") && !radarEnabledMainSelector.is(":checked")) {
                radarSection.show();
                if (radarContact.is(":checked")) {
                    radarContactSection.show();
                    radarAdmin.val("on");
                }
            } else {
                radarSection.hide();
                radarSection.find("select,input:not([type=checkbox]):not([type=radio])").val("");
                radarSection.find("input[type=checkbox]").removeAttr("checked").change();
                radarPreviousActive.removeAttr("checked").change();
                radarContact.removeAttr("checked").change();
                radarDuration.click().change();
                radarEndDate.datepicker("update");
                radarStartDate.datepicker("update");
            }
            if (radarEnabledMainSelector.is(":checked")) {
                $(this).removeAttr("checked");
                return false;
            }

            // show Meldeplattform Radar in R!-Section for Cities in Hessen
            if (ags && ags.substring(0,2) === "06") {
                ivmSwitcher.removeClass('hide');
            } else {
                ivmSwitcher.addClass('hide');
            }
        });

        if (!radarContact.is(":checked")) {
            radarContactSection.hide();
        }
        radarContact.off('click').on( 'click',function() {
            let radarAdmin = $("#sr_creg_radar_is_radar_admin_" + tabOffset);
            radarSection = $("#sr_creg_radar_section_" + tabOffset);
            radarEnabled = $("#sr_creg_radar_enabled_" + tabOffset);
            radarContact = $("#sr_creg_radar_contact_" + tabOffset);
            radarContactSection = $("#sr_creg_radar_contact_section_" + tabOffset);

            if (radarContact.is(":checked") && radarEnabled.is(":checked")) {
                radarContactSection.show();
                radarAdmin.val("on");
            } else {
                radarContactSection.find("select,input:not([type=checkbox]):not([type=radio])").val("").change();
                radarContactSection.find("input[type=checkbox]").removeAttr("checked").change();
                radarContactSection.find(".sr_form_feedback").html("");
                radarContactSection.hide();
            }
        });

        radarDateFromCampaign.off('click').on('click', function() {
            citySignupForm.update_enddate();
            let radarEndDate = $("#sr_creg_radar_end_date_" + tabOffset);
            let radarStartDate = $("#sr_creg_radar_start_date_" + tabOffset);
            let radarStartDateMain = $("#sr_creg_start_date");

            if (radarStartDateMain.val()) {
                radarStartDate.datepicker("setDate", radarStartDateMain.datepicker("getDate")).change();
            } else {
                radarStartDate.val("").change().datepicker("update");
            }
            if (citySignUpConfig.g_endDate) {
                radarEndDate.datepicker("setDate", citySignUpConfig.g_endDate).change();
            } else {
                radarEndDate.val("").change().datepicker("update");
            }
            citySignupValidation.validate_radar_date_range(tabOffset);
        });
    }

    /** geprüft */
    initializeSubCityEventListener(tabOffset, citySignupForm) {
        let coordination = $("#sr_creg_sub_coordination_" + tabOffset);
        let coordinationBox = $('.sr_creg_sub_coordination_box_' + tabOffset);
        let addExtraCoordination = $('.addSubExtraCoodination');
        const removeSubCity = $(".removeSubCity");
        const form = this;

        if (coordination.is(":checked")) {
            coordinationBox.show();
        } else {
            coordinationBox.hide();
        }
        coordination.off('click').on('click', () => {
           form.update_sub_coordination(tabOffset, citySignupForm);
        });

        addExtraCoordination.off('click').on('click', (e) => {
            this.add_sub_extra_coordination(citySignupForm, e.currentTarget.dataset.srOffset);
        });

        removeSubCity.off('click').on('click', async (e) => {
            console.log("sub city will be removed");
            this.remove_sub_commune(e.currentTarget, citySignupForm);
            citySignupForm.citySignupShoppingBasketInit.citySignupShoppingBasketUpdate.citySignupShoppingBasketCalculation.insertBasketJsonInField(await citySignupForm.citySignupShoppingBasketInit.citySignupShoppingBasketUpdate.citySignupShoppingBasketCalculation.refreshBasketJsonBasedOnFormular());
            citySignupForm.citySignupShoppingBasketInit.citySignupShoppingBasketUpdate.citySignupShoppingBasketCalculation.recreateShoppingBasketBasedOnBasketJson();
        });
    }

    /** geprüft */
    remove_sub_extra_coordination(element, citySignupForm)
    {
        citySignupForm.remove_extra_coordination(element);
    }

    addInitialSubCityHtml(subSelector, citySignupForm) {
        subSelector.addClass("container-sub-communes");
        subSelector.append(this.getNewSubCityHtml());
        this.initializeNewSubCity(citySignupForm, (this.getCurrentOffset()) * 100, false);
    }

    addSubCityTemplates(citySignupForm, offset, data, preLoad = false, agsData = false) {

        $('#subcity-existing-content-wrapper .accordion-template').append(this.subCityAccordeonWrapperTemplate(data.sr_creg_cityname, offset));

        $('.sr_creg_sub_coordination_box_' + offset).hide();

        // add new empty sub city form for the next sub city
        const lastOffsetEntry = document.querySelector('.akkordeon-topics:last-child').getAttribute('data-sr-offset');

        $('#subcity-new-content-wrapper .accordion-template').html(this.subCityAccordeonWrapperTemplate("Zugehörige Kommune / Gemeinde hinzufügen", (parseInt(lastOffsetEntry) + 100)));

        // fill the last saved sub city form with data
        this.fillSubCityFormWithData(citySignupForm, offset, data, preLoad, agsData);

        for (const key in data) {
            if (key === "sr_creg_ags") {
                let ret = $("#sr_kommunenregistrierung_sub_" + offset + " [name=" + key + "]:not([type=checkbox]):not([type=radio])").val(data[key]);
            }
        }

        // initialize the sub forms again (initialize select2`s) if its not from a pre load
        if (!preLoad) {
            this.initializeNewSubCity(citySignupForm, (parseInt(offset) + 100), citySignupForm.citySignupShoppingBasketInit);
        }

        // add event listener for the save button of new sub city form
        $('#subcity-new-content-wrapper .accordion-template .addSubCity').off('click').on('click', (event) => {
            this.checkOnMandatoryFieldsForSubCities(event.target.dataset.srOffset, citySignupForm);
        });

        // hide save button and show remove button
        const removeSubCityButton = document.querySelector('#removeSubCity_' + lastOffsetEntry);
        const addSubCityButtons = document.querySelectorAll('#subcity-existing-content-wrapper .addSubCity');

        removeSubCityButton.classList.remove('hidden');
        addSubCityButtons.forEach((item) => {
            $(item).off('click').on('click', (event) => {
                this.saveExistingSubCities(event.target.dataset.srOffset, citySignupForm);
            });
        });
    }

    addSubCity(citySignupForm) {
        // get data from current filled sub city form
        const offset = document.querySelector('#subcity-new-content-wrapper .akkordeon-topics').getAttribute('data-sr-offset');

        const data = this.collectDataFromSubCityForm(citySignupForm, offset)

        // check if sub city belongs to main city
        if ($('#sr_creg_country').val() === "57") {
            this.checkOnCorrectSubCity( citySignupForm, offset);
        }

        // create new empty sub city form with city name in titel in existing cities wrapper

        this.addSubCityTemplates(citySignupForm, offset, data, false);
    }

    async saveExistingSubCities(offset, citySignupForm) {
        const data = this.collectDataFromSubCityForm(citySignupForm, offset);
        this.checkOnMandatoryFieldsForSubCities(offset, citySignupForm, false);

        $('#akkordeon-wrapper_' + (offset) + ' .akkordeon-topic-link').html('<span class="missing-field-info hidden">!</span>' + data.sr_creg_cityname);
        $('#openFormSubCity_' + (offset )).collapse('toggle');

        citySignupForm.citySignupShoppingBasketInit.citySignupShoppingBasketUpdate.citySignupShoppingBasketCalculation.insertBasketJsonInField(await citySignupForm.citySignupShoppingBasketInit.citySignupShoppingBasketUpdate.citySignupShoppingBasketCalculation.refreshBasketJsonBasedOnFormular());
        citySignupForm.citySignupShoppingBasketInit.citySignupShoppingBasketUpdate.citySignupShoppingBasketCalculation.recreateShoppingBasketBasedOnBasketJson();
    }

    async checkOnMandatoryFieldsForSubCities(offset, citySignupForm,  addSubCity = true) {
        const mandatoryFields = citySignupForm.citySignupValidation.getMissingMandatoryFieldItems(document.querySelector('#sr_kommunenregistrierung_sub_' + offset));
        const mandatoryFieldModal = document.querySelector('#sr-creg-mandatory-field');
        mandatoryFieldArray["2"].push(mandatoryFields);

        if (mandatoryFields.length > 0) {
            $(mandatoryFieldModal).modal('show');

            $('#sr-creg-mandatory-field-abort').off('click').on('click', () => {
                const number = (offset / 100) - 1;
                $(mandatoryFieldArray[2][number])[0].item.scrollIntoView({behavior: "smooth", block: "center", inline: "center"});
            });
            $('#sr-creg-mandatory-field-finish').off('click').on('click', () => {
                if (addSubCity) {
                    if (this.checkIfAGSIsAlreadyUsed(document.querySelector('#sr_creg_ags_' + offset))) {
                        citySignupForm.citySignupValidation.showErrorMessage(3000, this.translationData.agsAlreadyInUse)
                    } else {
                        this.addSubCity(citySignupForm);
                    }
                }
                $('#akkordeon-wrapper_' + offset + ' .missing-field-info').removeClass('hidden');
            });
        } else {
            if (addSubCity) {
                if (this.checkIfAGSIsAlreadyUsed(document.querySelector('#sr_creg_ags_' + offset))) {
                    citySignupForm.citySignupValidation.showErrorMessage( 3000, this.translationData.agsAlreadyInUse)
                } else {
                    this.addSubCity(citySignupForm);
                }
            }
            $('#akkordeon-wrapper_' + offset + ' .missing-field-info').addClass('hidden');
        }

        citySignupForm.citySignupShoppingBasketInit.citySignupShoppingBasketUpdate.citySignupShoppingBasketCalculation.insertBasketJsonInField(await citySignupForm.citySignupShoppingBasketInit.citySignupShoppingBasketUpdate.citySignupShoppingBasketCalculation.refreshBasketJsonBasedOnFormular());
    }

    /** geprüft */
    addSubCityPreLoad(citySignupForm, data, agsData = false)
    {
        let citySignupAddSubCity = new CitySignupAddSubCity(this.data, this.translationData);
        const offset = citySignupAddSubCity.getCurrentOffset() * 100;

        // #6336 Prevent initialization from being called multiple times
        if (typeof this.initializedOffsets == "undefined" || !this.initializedOffsets) {
            this.initializedOffsets = {};
        }

        this.addSubCityTemplates(citySignupForm, offset, data, true, agsData);

        // eventlistener für daten von Kommune
        const akkordeonWrapper = document.querySelector('#akkordeon-wrapper_' + offset);
        $(akkordeonWrapper).off('click').on('click',() => {
            if (typeof this.initializedOffsets[offset] == "undefined" || !this.initializedOffsets[offset]) {
                this.initializeNewSubCity(citySignupForm, (this.getCurrentOffset()) * 100, citySignupForm.citySignupShoppingBasketInit, true);
                this.initializeSubCityRadar(offset, citySignupForm, citySignupForm.citySignupValidation);
                this.initializedOffsets[offset] = true;
            }
        });

        // Warenkorb muss aktualisiert werden
        if (citySignUpConfig.g_update_basket_disabled === false && this.data.pricingJson_currentYear !== false) {
            citySignupForm.citySignupShoppingBasketInit.citySignupShoppingBasketUpdate.update_basket_new($('#sr_kommunenregistrierung_sub_' + offset),'lk_attendence', true);
        }

        let coordination = $("#sr_creg_sub_coordination_" + offset);

        coordination.on('change', () => {
            this.update_sub_coordination(offset, citySignupForm);
        });

        return offset;
    }

    /** geprüft */
    remove_sub_commune(element, citySignupForm)
    {
        if (confirm(this.translationData.removeSubCity)) {
            // remove price from shopping basket
            $('.sub-commune[data-sr-offset="' + $(element).data('sr-offset') + '"]').remove();

            // remove akkordeon
            $(element).parents('#akkordeon-wrapper_' + $(element).data('sr-offset')).remove();
            //$('#akkordeon-wrapper_' + citySignupAddSubCity.getCurrentOffset() * 100).remove();

            // add new empty sub city form for the next sub city
            const lastOffsetEntry = document.querySelector('.akkordeon-topics:last-child').getAttribute('data-sr-offset');

            $('#subcity-new-content-wrapper .accordion-template').html(this.subCityAccordeonWrapperTemplate("Zugehörige Kommune / Gemeinde hinzufügen", (parseInt(lastOffsetEntry) + 100)));
            // initialize the sub forms again (initialize select2`s)

            this.initializeNewSubCity(citySignupForm, (parseInt(lastOffsetEntry) + 100));

            // add Eventlistener for submit button
            const addSubCity = document.querySelectorAll('#subcity-existing-content-wrapper .addSubCity');
            addSubCity.forEach((item) => {
                $(item).off('click').on('click', (event) => {
                    //this.checkOnMandatoryFieldsForSubCities(event.target.dataset.srOffset, citySignupForm, false);
                    this.saveExistingSubCities(event.target.dataset.srOffset, citySignupForm);
                });
            });
            const addNewSubCity = document.querySelector('#subcity-new-content-wrapper .addSubCity');
            $(addNewSubCity).off('click').on('click', (event) => {
                this.checkOnMandatoryFieldsForSubCities(event.target.dataset.srOffset, citySignupForm);
            });

            citySignupForm.citySignupShoppingBasketInit.citySignupShoppingBasketUpdate.update_basket_lk_attendence('remove','lk_attendence');

            // update the numbers of the shopping basket
            citySignupForm.citySignupShoppingBasketInit.citySignupShoppingBasketUpdate.updateNumbersOfSubCitiesInShoppingBasket();
        }
    }

    checkSingleSubCitiesOnMandatoryFields(citySignupForm, checkOnStepChange = false, pageChangeOnEmptyArray = true) {
        const citySignupInitialization = new CitySignupInitialization(this.translationData);
        const currentStep = document.querySelector('.step-city:not(.hidden)').getAttribute('data-step');

        $('#step-city-sub-cities').removeClass('hidden');
        $('#subcity-existing-content-wrapper .akkordeon-topics').each((index, item) => {
            $(item.querySelector('.akkordeon-topic-body')).collapse('show');
            const offset = item.querySelector('form').getAttribute('data-sr-offset');
            this.initializeSubCityRadar(offset, citySignupForm, citySignupForm.citySignupValidation);
            const mandatoryFields = citySignupForm.citySignupValidation.getMissingMandatoryFieldItems(document.querySelector('#sr_kommunenregistrierung_sub_' + offset));

            if(mandatoryFields.length > 0) {
                item.querySelector('.missing-field-info').classList.remove('hidden');
                mandatoryFieldArray["2"].push(mandatoryFields);

                if (checkOnStepChange) {
                    const mandatoryFieldModal = document.querySelector('#sr-creg-mandatory-field');
                    $(mandatoryFieldModal).modal('show');

                    $('#sr-creg-mandatory-field-abort').off('click').on('click', () => {
                        setTimeout(() => {
                            if (mandatoryFieldArray[2].length > 0) {
                                for (let subCity in mandatoryFieldArray[2][0]) {
                                    $(mandatoryFieldArray[2][0][subCity].item).parents('.akkordeon-topic-body').collapse('show');
                                    $(mandatoryFieldArray[2][0][subCity].item)[0].scrollIntoView({behavior: "smooth", block: "center", inline: "center"});
                                }
                            }
                        }, 1000);
                    });
                    $('#sr-creg-mandatory-field-finish').off('click').on('click', () => {
                        // different layout of submit buttons
                        citySignupInitialization.handlePageChange(citySignupForm.citySignupValidation, this.data);
                    });
                }

            } else {
                item.querySelector('.missing-field-info').classList.add('hidden');
            }
        });

        // close akkordeons again
        setTimeout(() => {
            $('.akkordeon-topics').each((index, item) => {
                $(item.querySelector('.akkordeon-topic-body')).collapse('hide');
            });
        }, 500);

        if (mandatoryFieldArray[2].length === 0 && pageChangeOnEmptyArray) {
            citySignupInitialization.handlePageChange(citySignupForm.citySignupValidation, this.data);
        }

        if (parseFloat(currentStep) !== 2) {
            $('#step-city-sub-cities').addClass('hidden');
        }
    }

    checkOnCorrectSubCity(citySignupForm, tabOffset) {
        const agsOfMainCity = document.querySelector('#sr_creg_ags').value;
        const agsOfSubCity = document.querySelector('#sr_creg_ags_' + tabOffset).value;
        const nameOfSubCity = document.querySelector('#sr_creg_cityname_' + tabOffset).value;

        const subCityAGSCheck = parseFloat(agsOfMainCity.substring(0, 5)) === parseFloat(agsOfSubCity.substring(0, 5));

        if (!subCityAGSCheck) {
            let hintMessage = "Der Regionalschlüssel der zugehörigen Kommune " + nameOfSubCity + " (" + agsOfSubCity + ") scheint nicht zu dem Kreis/Landkreis zu gehören<br>Bitte überprüfe deine Angaben";
            citySignupForm.citySignupValidation.showPermanentErrorMessage(hintMessage)
        }
    }

    checkIfAGSIsAlreadyUsed(usedAgs) {
        const allAgsFields = document.querySelectorAll('#subcity-existing-content-wrapper [name=sr_creg_ags]');
        const mainAgsField = document.querySelector('#sr_kommunenregistrierung_main [name=sr_creg_ags]').value;
        let agsArray = [];
        allAgsFields.forEach((item) => {
            agsArray.push(item.value);
        });
        agsArray.push(mainAgsField);

        return agsArray.indexOf(usedAgs.value) !== -1;
    }
}

window.CitySignupAddSubCity = CitySignupAddSubCity;
