/**
 * This class contains all field validations
 */

class CitySignupValidation {
    constructor(translation) {
        this.translation = translation;
    }

    /** geprüft */
    validation_register()
    {
        const form = this;
        const validateChecked = $(".sr_validate_checked");
        const validateCheckedAlways = $(".sr_validate_checked_always");
        const validateNoEmpty = $(".sr_validate_nonempty");
        const validateNumericIntGe0 = $(".sr_validate_numeric_int_ge0");
        const validateNumericIntGt0 = $(".sr_validate_numeric_int_gt0")
        const validateEmail = $(".sr_validate_email");
        const validateMinOrder = $(".sr_validate_min_order_amount");
        const validateOneIsChecked = $(".sr_validate_one_is_checked");

        validateOneIsChecked.click(function () {form.validate_one_is_checked(this)});
        validateOneIsChecked.change(function () {form.validate_one_is_checked(this)});

        validateChecked.click(function() { form.validate_checked(this); });
        validateChecked.change(function() { form.validate_checked(this); });

        validateCheckedAlways.click(function() { form.validate_checked(this); });
        validateCheckedAlways.change(function() { form.validate_checked(this); });

        validateNoEmpty.keyup(function() { form.validate_nonempty(this); });
        validateNoEmpty.change(function() { form.validate_nonempty(this); });

        validateNumericIntGe0.keyup(function() { form.validate_numeric_int_ge0(this); });
        validateNumericIntGe0.change(function() { form.validate_numeric_int_ge0(this); });

        validateNumericIntGt0.keyup(function() { form.validate_numeric_int_gt0(this); });
        validateNumericIntGt0.change(function() { form.validate_numeric_int_gt0(this); });

        validateEmail.keyup(function() { form.validate_email_or_empty(this); });
        validateEmail.change(function() { form.validate_email_or_empty(this); });

        validateMinOrder.keyup(function() { form.validate_min_order_amount(this); });
        validateMinOrder.change(function() { form.validate_min_order_amount(this); });
    }

    /** geprüft */
    getFeedbackElement(element) {
        let feedback_elem = $(element).nextAll("span.sr_form_feedback").eq(0);
        if (feedback_elem.length < 1) {
            feedback_elem = $(element).parents("tr").first().find("span.sr_form_feedback").eq(0);
        }

        return feedback_elem;
    }

    /** geprüft */
    validate_min_order_amount(element) {
        let feedback_elem = this.getFeedbackElement(element);
        let ident     = "<!--" + $(element).attr("name") + "-->";
        let msg_empty = ident + this.translation.messageMinOrder;
        let min_order_amount = parseFloat($(element).data('min-order-amount'));

        if ($(element).val() < min_order_amount && $(element).val() !== "0" && $(element).val() !== "") {
            feedback_elem.html(msg_empty);
        } else {
            if (feedback_elem.html() === msg_empty) {
                feedback_elem.html("");
            }
            return true;
        }
        return false;
    }

    /** geprüft */
    validate_checked(element)
    {
        element = $(element).children('input');
        let feedback_elem = this.getFeedbackElement(element);

        let ident     = "<!--" + $(element).attr("name") + "-->";
        let msg_empty = ident + this.translation.mandatoryField;

        if (!$(element).is(":checked")) {
            feedback_elem.html(msg_empty);
        } else {
            if (feedback_elem.html() === msg_empty) {
                feedback_elem.html("");
            }
            return true;
        }
        return false;
    }

    validate_one_is_checked(element)
    {
        element = $(element).find('input');
        let feedback_elem = this.getFeedbackElement(element);

        let ident     = "<!--" + $(element).attr("name") + "-->";
        let msg_empty = ident + this.translation.mandatoryField;
        let result = false;

        feedback_elem.html(msg_empty);
        element.each((index, item) => {

           if($(item).is(":checked") === true) {
               if (feedback_elem.html() === msg_empty) {
                   feedback_elem.html("");
               }

               result = true;
           }
        });
        return result;

    }

    /** geprüft */
    validate_nonempty(element)
    {
        let feedback_elem = this.getFeedbackElement(element);

        let ident     = "<!--" + $(element).attr("name") + "-->";
        let msg_empty = ident + this.translation.mandatoryField;
        if (!$(element).val() || $(element).val().trim() === "") {
            feedback_elem.html(msg_empty);
            $(element).addClass("feedback-form");
        } else {
            if (feedback_elem.html() === msg_empty) {
                feedback_elem.html("");
            }
            $(element).removeClass("feedback-form");
            return true;
        }

        return false;
    }

    /** geprüft */
    validate_email_or_empty(element)
    {
        let feedback_elem = this.getFeedbackElement(element);

        let ident       = "<!--" + $(element).attr("name") + "-->";
        let msg_invalid = ident + this.translation.notValidEmail;

        if ($(element).val().trim() !== "" &&
            ! /[^\s@]+@[^\s@]+\.[^\s@]+/.test( $(element).val().trim() ))
        {
            feedback_elem.html(msg_invalid);
        } else {
            if (feedback_elem.html() === msg_invalid) {
                feedback_elem.html("");
            }
            return true;
        }

        return false;
    }

    /** geprüft */
    validate_numeric_int_ge0(element)
    {
        let feedback_elem = this.getFeedbackElement(element);

        let val               = $(element).val();
        let ident             = "<!--" + $(element).attr("name") + "-->";
        let msg_pflichtangabe = ident + this.translation.notValidInteger;
        let msg_tausender     = ident + this.translation.notValidIntegerChars;
        let msg_ge0           = ident + this.translation.notValidIntegerBelowZero;

        if (val.trim() === "" || !$.isNumeric(val)) {
            feedback_elem.html(msg_pflichtangabe);
            $(element).addClass("feedback-form");
        } else if (Math.floor(val) !== parseInt(val) || val.indexOf(".") >= 0 || val.indexOf(",") >= 0) {
            feedback_elem.html(msg_tausender);
            $(element).addClass("feedback-form");
        } else if (val < 0) {
            feedback_elem.html(msg_ge0);
            $(element).addClass("feedback-form");
        } else {
            if (feedback_elem.html() === msg_pflichtangabe ||
                feedback_elem.html() === msg_tausender ||
                feedback_elem.html() === msg_ge0)
            {
                feedback_elem.html("");
            }
            $(element).removeClass("feedback-form");
            return true;
        }

        return false;
    }

    /** geprüft */
    validate_numeric_int_gt0(element)
    {
        let feedback_elem = $(element).nextAll("span.sr_form_feedback").eq(0);
        if (feedback_elem.length < 1) {
            feedback_elem = $(element).parents("tr").first().find("span.sr_form_feedback").eq(0);
        }

        let val               = $(element).val();
        let ident             = "<!--" + $(element).attr("name") + "-->";
        let msg_pflichtangabe = ident + this.translation.notValidInteger;
        let msg_tausender     = ident + this.translation.notValidIntegerChars;
        let msg_gt0           = ident + this.translation.notValidIntegerBelowZero;

        if (val.trim() === "" || !$.isNumeric(val)) {
            feedback_elem.html(msg_pflichtangabe);
            $(element).addClass("feedback-form");
        } else if (Math.floor(val) !== parseInt(val) || val.indexOf(".") >= 0 || val.indexOf(",") >= 0) {
            feedback_elem.html(msg_tausender);
            $(element).addClass("feedback-form");
        } else if (val <= 0) {
            feedback_elem.html(msg_gt0);
            $(element).addClass("feedback-form");
        } else {
            if (feedback_elem.html() === msg_pflichtangabe ||
                feedback_elem.html() === msg_tausender ||
                feedback_elem.html() === msg_gt0)
            {
                feedback_elem.html("");
            }
            $(element).removeClass("feedback-form");
            return true;
        }

        return false;
    }

    /** geprüft */
    validate_radar_date_range(tabOffset = 0)
    {
        let radarStartDateSelector = $("#sr_creg_radar_start_date");
        let radarEndDateSelector = $("#sr_creg_radar_end_date");
        let radarDateDays = $("#sr_creg_radar_date_days");

        if (tabOffset !== 0) {
            radarStartDateSelector = $("#sr_creg_radar_start_date_" + tabOffset);
            radarEndDateSelector = $("#sr_creg_radar_end_date_" + tabOffset);
            radarDateDays = $("#sr_creg_radar_date_days_" + tabOffset);
        }

        let end_date   = radarEndDateSelector.datepicker("getDate");
        let start_date = radarStartDateSelector.datepicker("getDate");


        if (end_date < start_date) {
            radarEndDateSelector.val("").datepicker("update");
            radarDateDays.html("");
            return false;
        } else {
            if (start_date && end_date) {
                let duration = (1 + this.date_diff_days(start_date, end_date));
                radarDateDays.html( "(" + duration + " " + this.translation.days + ")" );

                if (tabOffset !== 0) {
                    return true;
                } else {
                    return !($("#sr_creg_radar_duration_1").is(":checked") && duration > 21);
                }
            } else {
                radarDateDays.html("");
            }
        }

        return true;
    }

    /** geprüft */
    date_diff_days(date1, date2)
    {
        date1 = Date.UTC(date1.getFullYear(), date1.getMonth(), date1.getDate());
        date2 = Date.UTC(date2.getFullYear(), date2.getMonth(), date2.getDate());
        let ms = Math.abs(date1-date2);
        return Math.floor(ms/1000/60/60/24);
    }

    validateWithinAnElement(element, offsetParentNeed = true) {
        const form = this;
        const startDate = $("#sr_creg_start_date");
        const radarStartDate = $("#sr_creg_radar_start_date");
        const radarEndDate = $("#sr_creg_radar_end_date");
        let valid = true;
        let missingFieldIds = [];

        $(element.querySelector('#sr_creg_radar_form_feedback')).html("");
        if (startDate.val().trim()) {
            radarStartDate.addClass("sr_validate_nonempty");
            radarEndDate.addClass("sr_validate_nonempty");
        }

        for (let i=100; i<citySignUpConfig.g_tabOffset; i+=100) {
            if ($("form[data-sr-offset="+i+"]").length > 0) {
                if ($(element.querySelector('#sr_creg_start_date')).val().trim()) {
                    $(element.querySelector('#sr_creg_radar_start_date_' + i)).addClass('sr_validate_nonempty');
                    $(element.querySelector('#sr_creg_radar_end_date_' + i)).addClass('sr_validate_nonempty');
                }
            }
        }

        if ($(element.querySelector("#sr_creg_country")).val() === 57) {
            $(element.querySelectorAll(".sr_validate_phone_country")).each(function(index, element) {
                if (element.offsetParent !== null || offsetParentNeed === false) {
                    if (!$(element).val().trim()) {
                        $(element).val("+49").change();
                    }
                }
            });
        }

        $(element.querySelectorAll(".sr_validate_checked")).each(function(index, element) {
            if (element.offsetParent !== null || offsetParentNeed === false) {
                if (!form.validate_checked(element)) {
                    //console.log("sr_validate_checked:visible: Invalid element " + $(element).attr("name"));
                    valid = false;
                    missingFieldIds.push(element);
                }
            }
        });

        $(element.querySelectorAll(".sr_validate_checked_always")).each(function(index, element) {
            if (element.offsetParent !== null || offsetParentNeed === false) {
                if (!form.validate_checked(element)) {
                    //console.log("sr_validate_checked_always: Invalid element " + $(element).attr("name"));
                    valid = false;
                    missingFieldIds.push(element);
                }
            }
        });

        $(element.querySelectorAll(".sr_validate_nonempty")).each(function(index, element) {
            if (element.offsetParent !== null || offsetParentNeed === false) {
                if (!form.validate_nonempty(element)) {
                    //console.log("sr_validate_nonempty:visible: Invalid element " + $(element).attr("name"));
                    valid = false;
                    missingFieldIds.push(element);
                }
            }
        });

        $(element.querySelectorAll(".sr_validate_numeric_int_ge0")).each(function(index, element) {
            if (element.offsetParent !== null || offsetParentNeed === false) {
                if (!form.validate_numeric_int_ge0(element)) {
                    //console.log("sr_validate_numeric_int_ge0:visible: Invalid element " + $(element).attr("name"));
                    valid = false;
                    missingFieldIds.push(element);
                }
            }
        });

        $(element.querySelectorAll(".sr_validate_numeric_int_gt0")).each(function(index, element) {
            if (element.offsetParent !== null || offsetParentNeed === false) {
                if (!form.validate_numeric_int_gt0(element)) {
                    //console.log("sr_validate_numeric_int_gt0:visible: Invalid element " + $(element).attr("name"));
                    valid = false;
                    missingFieldIds.push(element);
                }
            }
        });

        $(element.querySelectorAll(".sr_validate_email")).each(function(index, element) {
            if (element.offsetParent !== null || offsetParentNeed === false) {
                if (!form.validate_email_or_empty(element)) {
                    valid = false;
                    missingFieldIds.push(element);
                }
            }
        });

        $(element.querySelectorAll(".sr_validate_one_is_checked")).each(function(index, element) {
            if (element.offsetParent !== null || offsetParentNeed === false) {
                if (!form.validate_one_is_checked(element)) {
                    valid = false;
                    missingFieldIds.push(element);
                }
            }
        })

        return {
            missingFieldIds: missingFieldIds,
            valid: valid,
        };
    }

    createArrayWithMissingMandatoryFieldIds() {
        const form = this;
        const startDate = $("#sr_creg_start_date");
        const radarStartDate = $("#sr_creg_radar_start_date");
        const radarEndDate = $("#sr_creg_radar_end_date");
        let valid = true;
        let missingFieldIds = [];
        let missingFieldNames = [];

        $("#sr_creg_radar_form_feedback").html("");
        if (startDate.val().trim()) {
            radarStartDate.addClass("sr_validate_nonempty");
            radarEndDate.addClass("sr_validate_nonempty");
        }
        for (let i=100; i<citySignUpConfig.g_tabOffset; i+=100) {
            if ($("form[data-sr-offset="+i+"]").length > 0) {
                if ($('#sr_creg_start_date').val().trim()) {
                    $('#sr_creg_radar_start_date_' + i).addClass('sr_validate_nonempty');
                    $('#sr_creg_radar_end_date_' + i).addClass('sr_validate_nonempty');
                }
            }
        }

        // #5060
        if ($("#sr_creg_country").val() === 57) {
            $(".sr_validate_phone_country:visible").each(function(index, element) {
                if (!$(element).val().trim()) {
                    $(element).val("+49").change();
                }
            });
        }
        $(".sr_validate_checked").each(function(index, element) {
            if (element.offsetParent !== null) {
                if (!form.validate_checked(element)) {
                    //console.log("sr_validate_checked:visible: Invalid element " + $(element).attr("name"));
                    valid = false;
                    missingFieldIds.push(element);
                }
            }
        });

        $(".sr_validate_checked_always").each(function(index, element) {
            if (element.offsetParent !== null) {
                if (!form.validate_checked(element)) {
                    //console.log("sr_validate_checked_always: Invalid element " + $(element).attr("name"));
                    valid = false;
                    missingFieldIds.push(element);
                }
            }
        });

        $(".sr_validate_nonempty:visible").each(function(index, element) {
            if (!form.validate_nonempty(element)) {
                //console.log("sr_validate_nonempty:visible: Invalid element " + $(element).attr("name"));
                valid = false;
                missingFieldIds.push(element);
            }
        });

        $(".sr_validate_numeric_int_ge0:visible").each(function(index, element) {
            if (!form.validate_numeric_int_ge0(element)) {
                //console.log("sr_validate_numeric_int_ge0:visible: Invalid element " + $(element).attr("name"));
                valid = false;
                missingFieldIds.push(element);
            }
        });

        $(".sr_validate_numeric_int_gt0:visible").each(function(index, element) {
            if (!form.validate_numeric_int_gt0(element)) {
                //console.log("sr_validate_numeric_int_gt0:visible: Invalid element " + $(element).attr("name"));
                valid = false;
                missingFieldIds.push(element);
            }
        });

        $(".sr_validate_email:visible").each(function(index, element) {
            if (!form.validate_email_or_empty(element)) {
                valid = false;
                missingFieldIds.push(element);
            }
        });

        $(".sr_validate_one_is_checked:visible").each(function(index, element) {
            if (!form.validate_one_is_checked(element)) {
                valid = false;
                missingFieldIds.push(element);
            }
        });

        radarStartDate.removeClass("sr_validate_nonempty");
        radarEndDate.removeClass("sr_validate_nonempty");
        for (let i=100; i<citySignUpConfig.g_tabOffset; i+=100) {
            $('#sr_creg_radar_start_date_' + i).removeClass('sr_validate_nonempty');
            $('#sr_creg_radar_end_date_' + i).removeClass('sr_validate_nonempty');
        }

        console.log(missingFieldIds);

        return {
            missingFieldIds: missingFieldIds,
            valid: valid,
        }
    }

    /** geprüft */
    validate_all(element = false, scrolling = true, offsetParentNeed = true)
    {
        let data = '';
        if (element) {
            data = this.validateWithinAnElement(element, offsetParentNeed)
        } else {
            data = this.createArrayWithMissingMandatoryFieldIds();
        }
        return this.getMissingSortedMissingFields(data.missingFieldIds, data.valid, scrolling);
    }

    getMissingSortedMissingFields(missingFieldIds, valid, scrollingEnabled = true) {
        let missingFieldHeight = [];
        missingFieldIds.forEach((item) => {
            if (item.type === "checkbox") {
                missingFieldHeight.push({
                    item: item.nextElementSibling,
                    position: item.nextElementSibling.offsetTop + $(item).parents('.commune-data')[0].offsetTop
                });
            } else {
                missingFieldHeight.push({
                    item: item,
                    position: $(item).parent()[0].offsetTop + $(item).parents('.commune-data')[0].offsetTop
                });
            }
        });

        missingFieldHeight.sort((a, b) => {
            return a.position - b.position
        });

        if(missingFieldHeight.length >= 1 && scrollingEnabled) {
            $(missingFieldHeight[0].item)[0].scrollIntoView({behavior: "smooth", block: "center", inline: "center"});
        }

        return {result: valid, items: missingFieldHeight};
    }

    showErrorMessage(timeout, text) {
        const utility = new Utility();
        utility.showErrorMessageInBanner(timeout, text);
    }

    showPermanentErrorMessage(text) {
        const utility = new Utility();
        utility.showPermanentErrorModal($('#sr-permanent-error-modal'), $('#sr-permanent-error-modal .sr-error-message-text'), text)
    }

    /**
     * get all missing mandatory field items
     * @returns {[]}
     */
    getMissingMandatoryFieldItems(element = false, scrolling = true, offsetParentNeed = true) {
        const validationArray = this.validate_all(element, scrolling, offsetParentNeed);
        return validationArray.items;
    }

    /**
     *  This method creates event listener on each input field to check if after a change there are still missing mandatory
     *  Fields. If no the (!) in the step wizard will disappear
     *
     */
    implementEventListenerForCheckingMandatoryFields() {

        // check if there is a missing field - if yes then show the "!" in the step wizzard
        const currentStep = document.querySelector('.step-city:not(.hidden)');
        const elements = currentStep.querySelectorAll('input');

        if (mandatoryFieldArray[currentStep.getAttribute('data-step')].length > 0) {
            elements.forEach((item) => {
                $(item).off('change').on('change', () => {
                    const step = document.querySelector('.sr-creg-step-city[data-step="' + currentStep.getAttribute('data-step') + '"]');

                    mandatoryFieldArray[currentStep.getAttribute('data-step')] = this.getMissingMandatoryFieldItems(false, false);
                    if (mandatoryFieldArray[currentStep.getAttribute('data-step')].length > 0) {
                        step.querySelector('.missing-field-info').classList.remove('hidden');
                    } else {
                        step.querySelector('.missing-field-info').classList.add('hidden');
                    }
                });
            })
        }
    }
}

window.CitySignupValidation = CitySignupValidation;
